import { Image } from 'antd-mobile'
import { AddSquareOutline } from 'antd-mobile-icons'
import { isEmpty } from 'lodash-es'
import { toJS } from 'mobx'
import { useLocalStore, observer } from 'mobx-react-lite'
import React, { useEffect } from 'react'

import usePopup from '@/components/NewEditForm/UILibrary/HYBVant/HPopup/hooks/usePopup'
import jobStore from '@/store/components/entryJob'

import Popup from './Popup'
import { dynamicFieldsList } from './service'
import { ModelPageTemplate } from './template'
import './index.less'

const ModelPage: React.FC<any> = ({ onConfirm, value, ...rest }) => {
  const { qrcodeId: _qrcodeId, modelPage, changeModelPageConfig } = useLocalStore(() => jobStore)

  const { open, onCancel, onOpen } = usePopup({})

  const { code = '', isAsync = false, staffNameInfo = {}, title = '', qrCodeId = '' } = rest || {}

  const { list = [] } = modelPage?.[code || ''] || {}

  const add = () => {
    changeModelPageConfig(code, { status: 'Add' })
    onOpen()
  }

  const fetchDynamicFieldsList = async () => {
    const { code: asCode, data } = await dynamicFieldsList(
      staffNameInfo?.employee_id || '',
      code || ''
    )
    if (asCode === 2000) {
      console
      changeModelPageConfig(code, {
        list: (data || [])?.map((x: any) => ({ ...x?.field_value, id: x?.id }))
      })
    }
  }

  const edit = (index: number) => {
    changeModelPageConfig(code, {
      status: 'Edit',
      list: list?.map((x: any, i: number) => {
        if (index === i) {
          x.active = 1
        } else {
          x.active = 0
        }
        return x
      })
    })
    onOpen()
  }

  useEffect(() => {
    isAsync && fetchDynamicFieldsList()
  }, [code, isAsync])

  useEffect(() => {
    onConfirm?.(list)
  }, [list?.length])

  console.log(toJS(list), 'modelpage>>>>', staffNameInfo, toJS(value), isAsync)
  return (
    <div className='hyb-com-concat'>
      {!isEmpty(list) && <ModelPageTemplate list={list} code={code} popup={edit} />}
      <div className='hyb-com-concat-tip'>
        <AddSquareOutline />
        <div className='hyb-com-concat-tip-title' onClick={add}>
          添加{title || ''}
        </div>
      </div>
      {open && (
        <Popup
          visible={open}
          onCancel={onCancel}
          popupData={rest}
          employee_id={staffNameInfo?.employee_id}
          isAsync={isAsync}
        />
      )}
    </div>
  )
}

export default observer(ModelPage)
