import { useSetState } from 'ahooks'
import { Checkbox, Radio, Toast } from 'antd-mobile'
import { RightOutline } from 'antd-mobile-icons'
import classNames from 'classnames'
import { throttle, cloneDeep } from 'lodash-es'
import { toJS } from 'mobx'
import { observer } from 'mobx-react-lite'
import React, { useEffect, Fragment } from 'react'

// import { useParams } from 'react-router-dom'
import KeywordHightlight from '@/components/KeywordHightlight'
import { HSearchBar } from '@/components/NewEditForm/UILibrary/HYBVant'
import HPopup from '@/components/NewEditForm/UILibrary/HYBVant/HPopup'

import { uniqueItems, flattenTree } from '@/utils'

import { employeeSelectorApi, getemployeeApi } from './services'

import './index.less'

interface PersonnelProps {
  visible: boolean
  type: string
  disabled?: boolean
  mode?: string
  onConfirm?: (employee: any[]) => void
  [key: string]: any
}

const Personnel: React.FC<PersonnelProps> = (props) => {
  const { type = '', mode = 'multiple', visible, onCancel, onConfirm, value } = props

  const { selectorParams = {}, personnelSearchRequestParams = {} } = props?.[type] || {}

  const [
    {
      show,
      count,
      popupVisible,
      defaultCount,
      cascader,
      selectAll,
      selectOrg,
      currentOrg,
      employee,
      selectEmployee,
      displayEmployee,
      searchStr,
      disabled,
      selectEmployeeOrg
    },
    setState
  ] = useSetState<any>({
    count: 0,
    defaultCount: 0,
    show: false,
    popupVisible: false,
    cascader: [],
    selectAll: false,
    selectOrg: [],
    currentOrg: {},
    employee: [],
    displayEmployee: [],
    selectEmployee: [],
    searchStr: '',
    disabled: [],
    selectEmployeeOrg: []
  })

  const onChange = async (keyword: string) => {
    const start = throttle(async () => {
      if (!keyword) {
        let params: any = {
          searchStr: ''
        }
        if (currentOrg.id && currentOrg?.metadata?.is_click === 1) {
          await fetchemployeeApi([currentOrg.id]).then((res: any) => {
            const { data } = res
            params = {
              searchStr: '',
              employee: data,
              // searchStr: '',
              displayEmployee: data
            }
          })
        } else {
          // eslint-disable-next-line @typescript-eslint/no-unused-vars
          params = {
            searchStr: '',
            employee: [],
            displayEmployee: []
          }
        }
        setState(params)
      }
      if (keyword) {
        const { data } = await getAllEmployee({ org_id_list: [], keyword })
        setState({ displayEmployee: data || [], searchStr: keyword, count: data?.length })
        return
      }
      // 当前页面搜索逻辑
      // const _currentOrg = cloneDeep(originalOrg)
      // _currentOrg.childs = _currentOrg?.childs.filter((x) => x?.name.includes(e.detail))
      // setState({
      //   displayEmployee: employee?.filter((x) => x?.name.includes(e.detail)),
      //   currentOrg: !e.detail ? originalOrg : _currentOrg
      // })
    }, 800)
    start()
  }

  const selectChildOrg = (_currentOrg: any) => {
    const exist: boolean = cascader?.some((x: any) => x?.id === _currentOrg?.id)
    let _cascader = []
    if (exist) {
      const index = cascader.indexOf(_currentOrg)
      _cascader = cascader.slice(0, index + 1)
    }
    setState({
      currentOrg: _currentOrg,
      originalOrg: cloneDeep(_currentOrg),
      cascader: exist ? _cascader : [...cascader, _currentOrg]
    })
  }

  const fetchEmployeeSelector = async () => {
    const { data } = await employeeSelectorApi(selectorParams)
    const orgList = (data?.item_infos || [])?.find((x: any) => x?.code === 'org')?.item_values || []
    setState({
      // selectorList: data || [],
      defaultCount: orgList?.[0]?.metadata?.all_count,
      cascader: [orgList?.[0]],
      currentOrg: cloneDeep(orgList?.[0] || {}),
      originalOrg: cloneDeep(orgList?.[0] || {})
    })
  }

  const getAllEmployee = async (params: Record<string, any>) => {
    return await getemployeeApi({
      paged: false,
      with_no_auth: params?.org_id_list?.length !== 0,
      ...params,
      ...personnelSearchRequestParams
    })
  }

  const fetchemployeeApi = async (org_id_list: Array<string>) => {
    return await getemployeeApi({
      org_id_list,
      paged: false,
      sub_flag: false,
      with_no_auth: true,
      ...personnelSearchRequestParams
    })
  }

  const confrimSelected = () => {
    if (selectEmployee?.length === 0) {
      Toast.show('请选择人员')
      return
    }
    console.log(selectEmployee, 'selectEmployee')
    onConfirm?.(selectEmployee)
  }

  useEffect(() => {
    fetchEmployeeSelector()
  }, [])

  useEffect(() => {
    setState({
      popupVisible: visible
    })
  }, [visible, setState])

  useEffect(() => {
    if (currentOrg.id && currentOrg?.metadata?.is_click === 1) {
      fetchemployeeApi([currentOrg.id]).then((res: any) => {
        const { data } = res
        setState({
          employee: data,
          // searchStr: '',
          displayEmployee: data
        })
      })
      return
    }
    setState({
      employee: [],
      searchStr: '',
      displayEmployee: []
    })
  }, [currentOrg])

  useEffect(() => {
    const getOrgSelectAll = (): boolean => {
      if (!currentOrg?.childs || currentOrg?.childs?.length === 0) return true
      if (currentOrg?.childs && currentOrg?.childs?.length > 0) {
        return disabled?.length === currentOrg?.childs?.length
      }
      return false
    }
    if (searchStr) return
    setState({
      selectAll:
        selectEmployee?.length > 0 &&
        displayEmployee?.every((x: any) =>
          selectEmployee?.map((y: any) => y?.id)?.includes(x?.id)
        ) &&
        getOrgSelectAll()
    })
  }, [currentOrg, employee, selectEmployee, disabled, searchStr, displayEmployee])

  const handleConvert = async (list: any[]) => {
    const asyncList: any[] = []
    list?.forEach((x: any, index: number) => {
      asyncList[index] = fetchemployeeApi([x?.id])
    })
    Promise.all(asyncList).then((res: any) => {
      const _list = selectEmployee?.map((x: any) => x.id)
      const _selectEmployeeOrg: any[] = []
      const _selectOrg: any[] = []
      const _value: any[] = []
      res.forEach((m: any, index: number) => {
        const { data } = m
        const curentList = data?.map((y: any) => y.id)
        if (curentList.every((n: any) => _list.includes(n))) {
          _selectEmployeeOrg.push(list[index])
          _selectOrg.push(list[index]?.id)
          _value.push(curentList)
        }
      })
      const _selectEmployee = selectEmployee?.filter((o: any) => !_value.flat().includes(o.id))
      setState({
        selectEmployeeOrg: [..._selectEmployee, ..._selectEmployeeOrg],
        selectOrg: _selectOrg,
        disabled: _selectOrg
      })
    })
  }

  // 回显代码2
  // useEffect(() => {
  //   const flattenTree = (tree: any, key: string = 'children') => {
  //     const flatArray: any = []

  //     function traverse(node: any) {
  //       flatArray.push(node)
  //       if (node?.[key] && node?.[key].length > 0) {
  //         if (node.metadata.all_count < value?.length && node.metadata.all_count !== 0) {
  //           handleConvert([])
  //         }
  //         node?.[key].forEach((child: any) => traverse(child))
  //       }
  //     }
  //     tree.forEach((rootNode: any) => traverse(rootNode))
  //     return flatArray
  //   }
  //   if (Object.keys(currentOrg).length === 0) return
  //   flattenTree([currentOrg])
  // }, [])

  // 回显代码
  // useEffect(() => {
  //   const list = currentOrg.childs
  //   if (Array.isArray(list) && list?.length && value && value?.length) {
  //     const searchPrepareOrgList = list.filter(
  //       (x: any) => x.metadata.all_count < value?.length && x.metadata.all_count !== 0
  //     )
  //     handleConvert(searchPrepareOrgList)
  //   }
  // }, [currentOrg])

  useEffect(() => {
    setState({ selectEmployee: value || [], selectEmployeeOrg: value || [] })
  }, [])

  const handleSelectOrg = async (params: { orgList: string[]; org: Record<string, any> }) => {
    if (mode === 'single') return
    const { orgList = [], org } = params
    const { data } = await getAllEmployee({ org_id_list: orgList })
    if (orgList.some((x) => disabled.includes(x))) {
      const _disabled: string[] = []
      const _selectEmployee: any[] = []
      const _selectEmployeeOrg: any[] = []
      for (const t of disabled) {
        if (!orgList.includes(t)) _disabled.push(t)
      }
      for (const t of selectEmployee) {
        if (!data.map((x: any) => x?.id)?.includes(t?.id)) _selectEmployee.push(t)
      }
      for (const t of selectEmployeeOrg) {
        if (!orgList?.includes(t?.id)) _selectEmployeeOrg.push(t)
      }
      setState({
        disabled: _disabled,
        selectEmployee: _selectEmployee,
        selectEmployeeOrg: _selectEmployeeOrg
      })
    } else {
      setState({
        disabled: [...disabled, ...orgList],
        selectEmployee: uniqueItems([...selectEmployee, ...data]),
        selectEmployeeOrg: [org, ...selectEmployeeOrg]?.filter(
          (x) => !data.map((y: any) => y?.id)?.includes(x?.id)
        )
      })
    }
  }

  const renderCount = () => {
    if (mode === 'single') return '1'
    if (defaultCount) return defaultCount
    // if (searchStr) return count
    return 0
  }

  const renderEmployee = () => {
    const ComponentList: Record<string, any> = {
      multiple: Checkbox.Group,
      single: Radio.Group
    }
    const ComponentChildList: Record<string, any> = {
      multiple: Checkbox,
      single: Radio
    }

    const _selectEmployee = selectEmployee?.map((x: any) => x?.id)

    const ComponentValue: Record<string, any> = {
      multiple: _selectEmployee,
      single: _selectEmployee?.[0]
    }

    const Component = ComponentList[mode]

    const ComponentChild = ComponentChildList[mode]

    const ComponentProps = {
      value: ComponentValue[mode]
    }

    return (
      <Component {...ComponentProps}>
        {displayEmployee?.map((x: any) => (
          <div key={x?.id} className='personnel-select-employee-item'>
            <ComponentChild
              onClick={() => {
                if (mode === 'single') {
                  setState({
                    selectEmployee: [x],
                    selectEmployeeOrg: [x]
                  })
                  return
                }
                if (selectEmployee?.map((m: any) => m?.id)?.includes(x?.id)) {
                  setState({
                    selectEmployee: selectEmployee?.filter((y: any) => y?.id !== x?.id),
                    selectEmployeeOrg: selectEmployeeOrg?.filter((y: any) => y?.id !== x?.id)
                  })
                } else {
                  setState({
                    selectEmployee: [...selectEmployee, x],
                    selectEmployeeOrg: [x, ...selectEmployeeOrg]
                  })
                }
              }}
              value={x?.id}
            >
              <div>
                <KeywordHightlight
                  text={`${x?.name}${x?.person_number ? `(${x?.person_number})` : ''}`}
                  keyword={searchStr}
                />
              </div>
            </ComponentChild>
          </div>
        ))}
      </Component>
    )
  }

  console.log(
    toJS(currentOrg),
    toJS(selectEmployeeOrg),
    props,
    disabled,
    selectOrg,
    'personnel>>>',
    displayEmployee,
    selectAll,
    count,
    selectEmployee
  )

  return (
    <HPopup
      visible={popupVisible}
      hasOk={false}
      onMaskClick={onCancel}
      closeOnMaskClick
      className='h-popup-person'
      bodyClassName='h-popup-person-content'
      onOk={confrimSelected}
      onCancel={onCancel}
    >
      <div className='personnel-select'>
        <div className='personnel-select-search'>
          <HSearchBar onChange={onChange} />
        </div>
        {/* <div className='personnel-select-display'>
            {cascader.map((x, index) => (
              <Fragment key={x.name}>
                <div
                  className={index > 0 ? 'personnel-select-display-name van-ellipsis-1' : ''}
                  onClick={() => selectChildOrg(x)}
                >
                  {x.name}
                </div>
                {index !== cascader.length - 1 && (
                  <RightOutline className='personnel-select-display-icon' />
                )}
              </Fragment>
            ))}
          </div> */}
        {cascader?.length > 0 && !searchStr && (
          <div className='personnel-select-display'>
            {/* <span
              className='personnel-select-display-back'
              onClick={() => selectChildOrg(originalOrg)}
            >
              回到顶级
            </span> */}
            {cascader?.map((x: any, index: any) => (
              <Fragment key={x?.name}>
                <span
                  className={index > 0 ? 'personnel-select-display-name van-ellipsis-1' : ''}
                  onClick={() => selectChildOrg(x)}
                >
                  {x?.name}
                </span>
                {index !== cascader.length - 1 && (
                  <RightOutline className='personnel-select-display-icon' />
                )}
              </Fragment>
            ))}
          </div>
        )}
        {mode === 'multiple' && (
          <div className='personnel-select-all'>
            <Checkbox
              checked={selectAll}
              onChange={async (flag) => {
                const orgList: string[] = currentOrg?.childs?.map((x: any) => x?.id) || []
                if (orgList.length === 0 || !!searchStr) {
                  setState({
                    selectAll: flag,
                    selectEmployee: flag ? [...displayEmployee] : [],
                    selectEmployeeOrg: flag ? [...displayEmployee] : []
                  })
                  return
                }
                const { data } = await getAllEmployee({ org_id_list: orgList })
                setState({
                  selectAll: flag,
                  disabled: flag ? orgList : [],
                  selectOrg: flag ? orgList : [],
                  selectEmployee: flag ? [...displayEmployee, ...data] : [],
                  selectEmployeeOrg: flag ? [...currentOrg?.childs, ...displayEmployee] : []
                })
              }}
            >
              全选
            </Checkbox>
          </div>
        )}
        <div className='personnel-select-employee'>
          {!searchStr && Array.isArray(currentOrg?.childs) && currentOrg?.childs.length > 0 && (
            <Checkbox.Group
              value={selectOrg}
              onChange={async (val) => {
                setState({ selectOrg: val })
              }}
            >
              {Array.isArray(currentOrg.childs) &&
                currentOrg.childs.map((x: any) => {
                  const nextDisabled = disabled.includes(x?.id)
                  return (
                    <div key={x?.id} className='personnel-select-org-item'>
                      <Checkbox
                        value={x?.id}
                        disabled={x?.metadata?.is_click === 0}
                        onClick={async () => {
                          handleSelectOrg({ orgList: [x.id], org: x })
                        }}
                      >
                        {x?.name}({x?.metadata?.all_count || 0})
                      </Checkbox>
                      <div
                        className={classNames('personnel-select-org-item-child', {
                          'personnel-select-org-item-child-disabled': nextDisabled
                        })}
                        onClick={() => (!nextDisabled ? selectChildOrg(x) : null)}
                      >
                        下级
                      </div>
                    </div>
                  )
                })}
            </Checkbox.Group>
          )}
          <div>{renderEmployee()}</div>
        </div>
        <div className='personnel-select-selected'>
          <div className='personnel-select-selected-n' onClick={() => setState({ show: true })}>
            已选择 （
            <span className='personnel-select-selected-n-i'>{selectEmployee.length || 0}</span>/
            {renderCount()}）
          </div>
          <div className='personnel-select-selected-confrim' onClick={confrimSelected}>
            确定
          </div>
        </div>
        <HPopup
          title='已选人员'
          visible={show}
          closeOnMaskClick
          showCloseButton
          hasOk={false}
          hasCancel={false}
          onClose={() => {
            setState({ show: false })
          }}
        >
          <div className='personnel-select-sheet'>
            {selectEmployee?.map((x: any) => (
              <div key={x?.id} className='personnel-select-sheet-item'>
                <div className='personnel-select-sheet-item-i'>
                  {x.name}
                  {x?.person_number ? `(${x?.person_number})` : ''}
                </div>
                <div
                  className='personnel-select-sheet-item-ii'
                  onClick={() => {
                    if (x?.code) {
                      handleSelectOrg({ orgList: [x?.id], org: x })
                      setState({ selectOrg: selectOrg?.filter((y: any) => y !== x?.id) })
                      return
                    }
                    setState({
                      selectEmployee: selectEmployee?.filter((y: any) => y?.id !== x?.id),
                      selectEmployeeOrg: selectEmployeeOrg?.filter((y: any) => y?.id !== x?.id)
                    })
                  }}
                >
                  移除
                </div>
              </div>
            ))}
          </div>
        </HPopup>
      </div>
    </HPopup>
  )
}

export default observer(Personnel)
