import { RightOutline } from 'antd-mobile-icons'
import React, { memo, useMemo } from 'react'
import { useNavigate } from 'react-router-dom'

import Expand from './Expand'
import useTimeline from './hooks/useTimeline'
import ImagePreview from './ImagePreview'

import './index.less'

const TimeLineClock: React.FC<any> = ({ date, dataSource }) => {
  const { transform } = useTimeline(date)
  const navigate = useNavigate()

  const dataList = useMemo(() => {
    return transform(dataSource)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [date, dataSource])

  const approvalClock = (item: any) => {
    if (item?.fieldwork_msg_id && item?.key) {
      navigate(`/notify/approve?id=${item?.key}&bi=${item?.fieldwork_msg_id}&t=1`)
    }
  }

  console.log('clock dataList:', dataSource, dataList)

  return (
    <div className='time-line-clock'>
      <div className='time-line-clock-inner'>
        {Array.isArray(dataList) && dataList?.length > 0 ? (
          dataList.map(
            (item: any, index: number) =>
              item && (
                <div key={`${index}`} className={`time-line-clock-item ${item.activeClass}`}>
                  <div className='time-line-clock-item-title'>{item.title}</div>
                  {item?.children?.map((child: any, childIndex: number) => (
                    <div className='time-line-clock-item-subs' key={`${item.id}_${childIndex}`}>
                      <div className={`time-line-clock-item-subtitle ${child.statusClass}`}>
                        {child.title}
                        {child.status && (
                          <div className={`time-line-clock-item-tag ${child.statusClass}`}>
                            {child.status}
                          </div>
                        )}
                        {(child.extraStatus ||
                          (child.within_geofence === 0 && !item?.fieldwork_msg_id)) && (
                          <div className={`time-line-clock-item-tag ${child.extraStatusClass}`}>
                            {child.extraStatus}
                          </div>
                        )}
                        {child.within_geofence === 0 && item?.fieldwork_msg_id && (
                          <div
                            className='time-line-clock-item-approval'
                            onClick={() => approvalClock(item)}
                          >
                            <span>前去审批</span> <RightOutline />
                          </div>
                        )}
                      </div>
                      {child.content || child.approval ? (
                        <div className='time-line-clock-item-content'>
                          {child.content ? (
                            <div
                              className={`time-line-clock-item-content-inner ${child.clockClass}`}
                            >
                              {child.content}
                            </div>
                          ) : null}
                          {child.approval ? (
                            <div className='time-line-clock-item-content-desc'>
                              {child.approval}
                            </div>
                          ) : null}
                          <Expand dataSource={child} />
                          {child.attachments?.length > 0 && (
                            <div className='time-line-clock-item-content-image'>
                              {child.attachments.map((y: any, itemIndex: number) => (
                                <ImagePreview
                                  key={`${y?.image_url}_${itemIndex}`}
                                  src={y?.image_url}
                                />
                              ))}
                            </div>
                          )}
                        </div>
                      ) : null}
                    </div>
                  ))}
                </div>
              )
          )
        ) : (
          <>
            <div className='time-line-clock-item active'>
              <div className='time-line-clock-item-title'>上班</div>
            </div>
            <div className='time-line-clock-item'>
              <div className='time-line-clock-item-title'>下班</div>
            </div>
          </>
        )}
      </div>
    </div>
  )
}

export default memo(TimeLineClock)
