import { Toast } from 'antd-mobile'
import axios from 'axios'
import CryptoJS from 'crypto-js'
import dayjs from 'dayjs'
import { cloneDeep } from 'lodash-es'

/** 日志 */
import logger from './logger'
import service from './service'

/** request  */
export { default as http } from './http'

/** 存储 */
export * from './storage'

/** 微信相关 */
export * from './wxBridge'

/** 工具 */
export * from './tools'

/** history */
export * from './history'

/** 环境 */
export * from './env'

export const clearToast = (count?: number) => {
  setTimeout(() => {
    Toast.clear()
  }, count || 2000)
}
/**
 *将时间戳格式化成日期
 * @param date
 * @returns
 */
export const handleTimestampToDate = (
  date?: string | number | undefined,
  dateFormat = 'YYYY-MM-DD'
) => {
  return date ? dayjs(Number(date)).format(dateFormat) : ''
}

/**
 * 判断变量类型 示例isTypeof(v).isFunction
 * @param v any
 */
export function isTypeof(v: any): {
  isNull: boolean
  isUndefined: boolean
  isString: boolean
  isNumber: boolean
  isBoolean: boolean
  isFunction: boolean
  isPromise: boolean
  isDate: boolean
  isArray: boolean
  isRegExp: boolean
  isJSON: boolean
  isObject: boolean
  isText: boolean
  isHTML: boolean
} {
  const typeString = Object.prototype.toString.call(v).slice(8, -1)
  const typeArray = [
    'Null',
    'Undefined',
    'String',
    'Number',
    'Boolean',
    'Function',
    'Promise',
    'Date',
    'Array',
    'RegExp',
    'JSON',
    'Object',
    'Text',
    'HTML'
  ]
  const typeMap: any = {}
  typeArray.forEach((t: string) => {
    if (t === 'HTML') {
      typeMap[`is${t}`] = typeString.match(t)
    } else {
      typeMap[`is${t}`] = typeString === t
    }
  })
  return typeMap
}

/**
 * 转换成json对象
 * @param jsonString
 * @returns
 */
export const toJson = (jsonString: any) => {
  try {
    const format = jsonString
      .replace(/\s/g, '')
      .replace(/([{,]\s*)([A-Za-z0-9_]+?)\s*:/g, '$1"$2":')
      .replace(/:\s*"([^"]*)"/g, ':"$1"')
      .replace(/'/g, '"')
    const json = JSON.parse(format)
    console.log('parse:', json)
    return json
  } catch (e) {
    console.error('parse error:', e)
    logger(e)
  }
}
/**
 * 自定义格式化日期显示。
 * 此函数接受一个日期信息 `dateInfo` 和一个可选的日期格式字符串 `text`。
 * 它将 `dateInfo` 根据提供的格式（或默认的 'YYYY-MM-DD' 格式，如果未提供）转换为易读的日期字符串。
 * 如果 `dateInfo` 为空或无效，则返回 `null`。
 * @param {any} dateInfo - 需要格式化的日期对象或时间戳。
 * @param {string} [dateFormat='YYYY-MM-DD'] - 用于格式化的日期格式字符串，默认为年月日格式。
 * @returns {moment.Moment | null} - 格式化后的 `moment` 对象，如果输入无效则返回 `null`。
 */
export const transformAndEchoDate = (dateInfo: any, dateFormat?: string) => {
  return dateInfo ? dayjs(dateInfo, dateFormat || 'YYYY-MM-DD') : null
}
/**
 * 格式化日期信息并输出。
 * 此函数接收一个日期对象（info）和一个可选的日期格式字符串（text）。若日期对象存在且非空，
 * 则将日期对象按照指定格式（默认为'YYYY-MM-DD'）进行格式化并返回；否则返回null。
 * @param dateInfo 日期对象，通常是日期时间实例。
 * @param [dateFormat] 日期格式字符串，用于自定义日期显示格式。如未提供，则使用默认格式'YYYY-MM-DD'。
 * @returns 格式化后的日期字符串，或在日期对象不存在时返回null。
 */
export const formatAndEchoDate = (dateInfo: any, dateFormat?: string) => {
  return dateInfo ? dayjs(dateInfo).format(dateFormat || 'YYYY-MM-DD') : null
}
/**
 * 获取基于日期类型的文本背景处理函数。
 * 此函数根据传入的日期信息（dateInfo）、日期格式（dateFormat）以及日期类型（dateType）
 * 来决定如何处理日期并返回相应的字符串。支持两种日期类型：
 * - 'echoDate': 使用moment.js将日期对象转换为日期时间实例。
 * - 'formatEchoDate': 格式化日期对象为字符串。
 * 若未提供日期类型或设置为其他值，将默认执行格式化操作。
 * @param inputParams 参数对象，包含日期信息、日期格式和日期类型。
 * @returns 根据日期类型处理后的日期字符串，或在日期信息不存在时返回null。
 */
export const getDateTextHandler = (inputParams: any) => {
  const { dateInfo, dateFormat, dateType } = inputParams
  let textContent: any = ''
  switch (dateType) {
    case 'echoDate':
      textContent = transformAndEchoDate(dateInfo, dateFormat)
      break
    case 'formatEchoDate':
      textContent = formatAndEchoDate(dateInfo, dateFormat)
      break
    default:
      textContent = transformAndEchoDate(dateInfo, dateFormat)
  }
  return textContent
}
/**
 * 处理并输出日期范围的文本表示。
 * 此函数接收一个包含日期范围信息、日期格式及处理类型的参数对象（inputParams）。
 * 它会分别处理日期范围的开始和结束日期，利用`getDateTextHandler`函数对每个日期进行格式化或转换，
 * 然后以数组形式返回这两个处理后的日期。如果开始或结束日期任一存在，则返回两者处理后的结果；
 * 否则，返回null。
 * @param rangeDateParams 包含日期范围信息、格式及处理类型的参数对象。
 *   - dateInfo: 一个包含两个元素的数组，分别代表开始和结束日期。
 *   - dateFormat: 用于格式化的日期格式字符串。
 *   - dateType: 日期处理类型，指导如何处理日期。
 * @returns 一个数组，包含处理后的开始日期字符串和结束日期字符串，或在两者都缺失时返回null。
 */
export const handleAndEchoDateRange = (rangeDateParams: any) => {
  const { dateInfo, dateFormat, dateType } = rangeDateParams
  const [start_date, end_date] = dateInfo
  // 分别处理开始和结束日期
  const startDateStr = getDateTextHandler({ dateInfo: start_date, dateFormat, dateType })
  const endDateStr = getDateTextHandler({ dateInfo: end_date, dateFormat, dateType })
  // 如果开始或结束日期至少有一个存在，则组合返回
  return startDateStr || endDateStr ? [startDateStr, endDateStr] : null
}
/**
 * 从form_item中检索指定属性的字符串值。
 * 首先尝试从form_item的elementProps中获取属性值，如果不存在，则直接从form_item中获取。
 * 如果两者都未找到，则返回空字符串。
 * @param form_item 需要检索的数据对象，可能包含elementProps属性。
 * @param str 要检索的属性名称。
 * @returns 属性对应的字符串值，如果未找到则返回空字符串。
 */
export const retrieveStringProperty = (form_item: any, str: string): string => {
  return form_item?.formItemProps?.[str] || form_item?.[str] || ''
}
export function appendSuffixToLastElement(array: any, suffix = 's') {
  // 复制数组的最后一项并加上's'
  const lastElementWithS = array[array.length - 1] + suffix
  // 替换数组中的最后一个元素为新值
  array[array.length - 1] = lastElementWithS
  return array
}
// 深拷贝方法
export const cloneDeepData = (list: any) => {
  return cloneDeep(list || [])
}
function appendToPath(basePath, suffix) {
  const parts = basePath
  parts[parts.length - 1] += suffix
  return parts
}
export function getValueByPath(obj, path) {
  return getTypeof(path) === 'Array' ? path?.reduce((o, k) => o?.[k], obj) : obj?.[path]
}
export function getValueDataByPath(str: any, path: any) {
  return path?.map((x) => x?.[str]) || null
}
/**
 * 获取数组的最后一个元素或非数组的原始值。
 * 此函数旨在提供一种统一的方式来处理数组和非数组值。当输入是一个数组时，它返回数组的最后一个元素；
 * 当输入不是数组时，它直接返回该值。这种设计使得调用者不需要关心具体的输入类型，简化了代码逻辑。
 * @param value 任意类型的输入值。可以是一个数组，也可以是任何其他类型的值。
 * @returns 如果输入是一个数组，则返回数组的最后一个元素；如果输入不是数组，则返回原始值。
 */
// export const getLastElementOrValue = (value: any) => {
//   return Array.isArray(value) ? value?.slice(-1)?.[0] : value
// }
// 假设valueTexts和valueTextNames都是数组类型，数组的元素类型为T
export function mapValuesAndTexts<T>(
  valueTexts: T[],
  valueTextNames: string[],
  str_value: string,
  str_text: string
) {
  return (
    valueTexts?.map((valueText: any, index: number) => {
      const obj = {}
      obj[str_value] = valueText
      obj[str_text] = valueTextNames?.[index]
      return obj
    }) || []
  )
}

export function setNestedValue<T = any>(obj: T, keys: any, value: any): void {
  let current: any = obj
  if (getTypeof(keys) === 'String') {
    current[keys] = value
  } else {
    for (let i = 0; i < keys.length - 1; i++) {
      const key = keys[i]
      if (!current[key]) {
        current[key] = {} as T extends object ? T : {}
      }
      current = current[key]
    }
    current[keys[keys.length - 1]] = value
  }
}
/**
 * 根据表单配置及数据信息，转化并优化数据格式。
 * 此函数分析传入的表单结构（jsonForm）与数据实体（valueInfo），针对性地转换日期类型字段，
 * 确保非日期字段的正确处理（赋予null或原值），同时检测表单配置中是否有动态字段的指示。
 * @param formConfig 表单配置对象数组，描述了表单项的类型与名称等信息。
 * @param formData 需要转换格式的原始数据对象，对应用户填写或系统生成的数据。
 * @param [businessType] 业务类型标识，当前函数逻辑中未直接利用此参数。
 * @param [businessCode] 业务代码标识，同上，当前实现中未直接参与逻辑处理。
 * @returns 转换结果对象，结构如下：
 * - formattedData: 经过格式化处理后的数据，特别地，日期字段已按需转换格式。
 * - hasDynamicFields: 布尔值，指示表单配置中是否存在动态表单项。
 * - dynamicDataHolder: 用于存放动态生成的数据信息，默认为空对象，当前未实际填充数据。
 */
export const formatNameArrayWithConfig = ({
  formConfig,
  formData
}: {
  formConfig: any
  formData: any
}) => {
  const formattedData: any = {}
  let hasDynamicFields = false
  // 遍历表单配置，处理数据
  formConfig?.formInfo?.forEach((section: any) => {
    hasDynamicFields = !!section?.form_list?.length
    section?.form_list?.forEach((field: any) => {
      const specific_type = field?.specific_type || ''
      const value_n = retrieveStringProperty(field, 'name')
      const value_name: any = value_n || []
      const value = value_name?.length ? getValueByPath(formData, value_n) : null
      const form_type = retrieveStringProperty(field, 'type')
      const format_text = retrieveStringProperty(field, 'format')
      if (form_type === 'DatePicker') {
        const value_ = getDateTextHandler({
          dateInfo: value,
          dateFormat: format_text || '',
          dateType: 'formatEchoDate'
        })
        setNestedValue(formattedData, value_name, value_)
      } else if ([form_type, specific_type].includes('ApplyPersonnelSelect')) {
        const value_ = getValueDataByPath('id', value || [])
        setNestedValue(formattedData, value_name, value_)
      } else if ([form_type, specific_type].includes('Organize')) {
        const org_id = formData?.work_map?.org_id?.[0]?.id || null
        setNestedValue(formattedData, value_name, org_id)
      } else {
        let form_item_name: any
        if (value === 0 || value) {
          form_item_name = value
        } else {
          form_item_name = null
        }
        setNestedValue(formattedData, value_name, form_item_name)
      }
    })
  })

  return { formattedData, hasDynamicFields }
}
/**
 * 深度合并两个对象的属性。
 * @param target 被合并的对象，合并后的属性将修改此对象。
 * @param source 合并源对象，其属性将被合并到目标对象中。
 * @returns 返回合并后的目标对象。
 */
export function deepMerge(target: any, source: any) {
  Object.keys(source).forEach((key) => {
    const value = source[key]
    if (typeof value === 'object' && value !== null && !Array.isArray(value)) {
      if (!Object.prototype.hasOwnProperty.call(target, key)) target[key] = {}
      deepMerge(target[key], value)
    } else if (value !== undefined) {
      // 新增条件，跳过undefined和null值
      target[key] = value
    }
  })
  return target
}
export function ensureObjectProperties(obj: any) {
  return {
    base_map: obj?.base_map ?? {},
    contact_map: obj?.contact_map ?? {},
    salary_card_map: obj?.salary_card_map ?? {},
    work_map: obj?.work_map ?? {}
  }
}
interface Employee {
  // 员工对象的属性定义
  [key: string]: any
}
/**
 * 根据指定属性丰富员工数据，并按属性名组织结果。
 * 如果有数据被丰富，则返回一个包含该属性名的对象；
 * 否则，返回一个空对象。
 *
 * @param employees - 员工数据数组。
 * @param identifier - 要添加到每个员工对象中的属性名称（如 'employee_id'）。
 * @param targetProperty - 结果对象中的属性名，用于存储丰富后的数据数组。
 * @returns 包含丰富数据或为空的对象。
 */
export function enrichAndOrganizeEmployees(
  employees: Employee[],
  identifier: string,
  targetProperty: string
): { [key: string]: Employee[] } | { [key: string]: any } {
  if (!Array.isArray(employees)) {
    return {}
  }

  // 丰富每个员工对象，添加指定属性
  const enrichedEmployees = employees.map((employee) => ({ ...employee, employee_id: identifier }))

  // 根据丰富后的数据是否存在来决定返回内容
  return enrichedEmployees.length > 0 ? { [targetProperty]: enrichedEmployees } : {}
}
/**
 * 使用标识符丰富并组织图片数据。
 *
 * @param images - 图片资源数组。
 * @param typeId - 用于标注图片类型的标识符。
 * @returns 如果图片数组非空，则返回一个包含类型和图片详情的对象；否则，返回空数组。
 */
export function organizeImagesWithDetails(images: string[], typeId: number): any[] | [] {
  // 确保输入是图片URL数组
  if (!Array.isArray(images)) {
    return []
  }
  // 仅当有图片时构建丰富数据结构
  return images.length > 0
    ? [
        {
          type: typeId,
          details: [{ oss_key: images }]
        }
      ]
    : []
}

export const FiledObjCalculate = (
  condition: boolean,
  obj: Record<string, any>,
  _?: Record<string, any>
) => {
  return condition ? obj : _ || {}
}

export const FiledArrayCalculate = (condition: boolean, arr: any[], _?: any[]) => {
  return condition ? arr : _ || []
}

export function getUrlParams(): Record<string, string> {
  const url = window.location.href.replace(/#/, '').replace(/wechat_redirect/g, '')
  const search = decodeURIComponent(new URL(url).search)
  const searchParams = new URLSearchParams(search)
  const params: Record<string, string> = {}
  for (const [key, value] of searchParams.entries()) {
    params[key] = value
  }

  return params
}

export function totUrlParams(urlParam: Record<string, string>): string {
  const querys: string[] = []
  for (const [key, value] of Object.entries(urlParam)) {
    querys.push(`${encodeURIComponent(key)}=${encodeURIComponent(value)}`)
  }
  return querys.join('&')
}
// 使用表达式取值
export const toRegExpValue = ({ key, data }: any) => {
  const regexp = /^(.*){(.+)}(.*)$/
  const keys = [
    key.replace(regexp, `$1`),
    data[key.replace(regexp, `$2`)],
    key.replace(regexp, `$3`)
  ]
  return keys.join('')
}
/**
 * 表达式转换值
 * 支持类型：-1 = 使用{}表达式拼接  ({task_name}类型)
 * 支持类型：0 = 直接取值          task_name
 * 支持类型：1 = 使用|按顺序取值多个字段  task_name|task_id
 * 支持类型：2 = 使用-按拼接多个字段  task_name-task_code
 */
export const toExpressionValue = ({ key, data, divide }: any) => {
  if (!key || !data) return ''
  let keyType = key.match(/\|/) ? 1 : key.match(/\-/) ? 2 : 0
  if (key.match(/\//)) {
    keyType = 3
  }
  if (key.match(/(\.\[\]\.)/)) {
    keyType = 4
  }
  if (key.match(/^.*{.+}.*$/)) {
    keyType = -1
  }
  // console.log('keyType:', keyType)
  switch (keyType) {
    case -1:
      return toRegExpValue({ key, data })
    case 0:
      return data[key]
    case 1:
      return data[key.split('|').find((item: string) => data[item])]
    case 2:
      return key
        .split('-')
        .map((item: string) => data[item])
        .filter((item: string) => item)
        .join(divide || '-')
    case 3:
      return key
        .split('/')
        .map((item: string) => data[item])
        .join(divide || '/')
    case 4:
      return (
        data?.[key.split(/(\.\[\]\.)/)?.[0]]
          ?.map?.((item: any) => item[key.split(/(\.\[\]\.)/)?.[2]] || '-')
          ?.join?.(divide || '；') || ' '
      )
  }
}

export const initJSBridge = (onReady?: Function) => {
  function ready() {
    // @ts-ignore
    if (window.__wxjs_environment === 'miniprogram') {
      onReady?.()
    }
  }
  // @ts-ignore
  if (!window.WeixinJSBridge || !window.WeixinJSBridge.invoke) {
    document.addEventListener('WeixinJSBridgeReady', ready, false)
  } else {
    ready()
  }
}
export const miniProgramNavigateTo = (url: string) => {
  // @ts-ignore
  wx.miniProgram.getEnv(function (res) {
    if (res.miniprogram) {
      // @ts-ignore
      wx.miniProgram.navigateTo({ url })
    }
  })
}
export const isNullOrUndefined = (v: any) => v === null || v === undefined

export const getTreeKeyData = (tree: any[] = []) => {
  const menu: string[] = []
  const func: string[] = []
  const path: string[] = []

  const traverse = (node: Record<string, any>) => {
    menu.push(node?.code)
    func.push(node?.function_permissions)
    node?.path && path.push(node?.path)
    if (node?.children && Array.isArray(node?.children)) node?.children.forEach((y) => traverse(y))
  }

  tree.forEach((x) => traverse(x))
  return [menu, func, path]
}

/**
 * 是否为非空数组
 * @param arr
 * @returns
 */
export const isNotEmptyArray = (arr: unknown) => Array.isArray(arr) && !!arr.length

/**
 *
 * @param data 要转换的数据，数组
 * @param mapping 转换映射
 * @returns
 */
export const transformData = (
  data: any[],
  mapping?: {
    [key: string]: string
  }
): any[] => {
  const { label = 'name', value = 'code', children = 'children' } = mapping || {}

  if (!isNotEmptyArray(data)) {
    return data
  }
  return data.map((item) => {
    const transformedItem: any = {
      value: item[value],
      label: item[label],
      ...item
    }

    // 递归转换子节点
    if (isNotEmptyArray(item[children])) {
      transformedItem[children] = transformData(item[children], mapping)
    }
    return transformedItem
  })
}

/**
 * @description 数据转换成以key[id]为索引的集合
 * @param data 要转换的数据，数组
 * @param mapping 转换映射
 * @returns
 */
export const transformDataToMap = (
  data: any[],
  mapping?: {
    [key: string]: string
  }
): any[] => {
  const { children = 'children', key = 'id' } = mapping || {}

  if (!isNotEmptyArray(data)) {
    return data
  }
  return data.reduce((res, item) => {
    let temp = {}
    res[item[key]] = item
    if (isNotEmptyArray(item[children])) {
      temp = transformDataToMap(item[children], mapping)
    }
    return { ...res, ...temp }
  }, {})
}

/**
 * 查询匹配
 * @param param0
 * @returns
 */
export const findTreeNode = ({
  data,
  propName,
  propValue
}: {
  data: any
  propName: string
  propValue: any
}): any => {
  // 如果当前层级找不到，继续在子节点中查找
  for (let i = 0; i < data.length; i++) {
    if (data[i][propName] === propValue) {
      return data[i].children
    }
    if (data[i].children?.length) {
      const result = findTreeNode({
        data: data[i].children,
        propName,
        propValue
      })
      if (result) {
        return result
      }
    }
  }

  // 如果遍历完整个树都找不到对应 value 的节点，返回 null 或者其他你认为合适的值
  return null
}
export function findTreeNodeById(tree: any[], id: string): any | undefined {
  for (const node of tree) {
    if (node.id === id) {
      return node
    }
    if (node.children && node.children.length > 0) {
      const foundNode = findTreeNodeById(node.children, id)
      if (foundNode) {
        return foundNode
      }
    }
  }
  return undefined
}

/**
 * 判断变量类型 示例isTypeof(v).isFunction
 * @param v any
 */
export function getTypeof(v: any): string {
  return Object.prototype.toString.call(v).slice(8, -1)
}

export const toPx = (num: number): number => {
  const width = window.document.documentElement.clientWidth
  return Math.floor(num * (width / 750))
}

export const uniqueItems = (items: any[]) => {
  return items.filter((item, index, self) => index === self.findIndex((t) => t.id === item.id))
}
// 考勤汇总 (/pages/Attendance/Attencount/index) / 排班期望 (/pages/Schedule/Expectation/index) / 工资单 (/pages/Attendance/Payroll/index) / 蓝牙设备 (/pages/Device/List/index) / 招聘需求 (/pages/WebViewUrl/index?path=/Recruit/NeedsTabs/index) / 招聘任务 (/pages/WebViewUrl/index?path=/Recruit/TaskTabs/index)
export const urlConvert = (info: any) => {
  const { path = '', name = '开发中' } = info
  let textStr: any = ''
  switch (path) {
    case '/pages/H5/index?path=/schedule/advanced':
      textStr = '/schedule/advanced' // 移动排班
      break
    case '/pages/H5/index?path=/attendance/staffDaily':
      textStr = '/attendance/staffDaily' // 考勤日历
      break
    case '/pages/H5/index?path=/attendance/teamDaily':
      textStr = '/attendance/teamDaily' // 团队日考勤
      break
    case '/pages/H5/index?path=/attendance/teamMonthly':
      textStr = '/attendance/teamMonthly' // 团队月考勤
      break
    case '/pages/H5/index?path=/flow/apply':
      textStr = '/flow/apply' // 我的申请
      break
    case '/pages/H5/index?path=/flow/approval':
      textStr = '/flow/approval' // 我的审批
      break
    case '/pages/H5/index?path=/entry/stay':
      textStr = '/entry/stay' // 待入职
      break
    case '/pages/H5/index?path=/apply/myInventory':
      textStr = '/apply/myInventory' // 库存余额
      break
    case '/pages/H5/index?path=/apply/qrcode':
      textStr = '/apply/qrcode' // 门店二维码
      break
    case '/pages/H5/index?path=/salary/payroll':
      textStr = '/salary/payroll' // 工资单
      break
    case '/pages/H5/index?path=/attendance/attencount':
      textStr = '/attendance/attencount' // 考勤汇总
      break
    case '/pages/H5/index?path=/schedule/my':
      textStr = '/schedule/my' // 我的排班
      break
    case '/pages/H5/index?path=/schedule/team':
      textStr = '/schedule/team' // 团队排班
      break
    case '/clock':
      textStr = '/clock' // 打卡
      break
    default:
      textStr = `/my/blankPage?pageTitle=${name}` // 开发中
  }
  return textStr
}
export async function createFileFromUrl(url: string, filename: string): Promise<File> {
  try {
    console.log('axios:', url, filename)
    // 使用 axios 发起 GET 请求获取资源
    const response = await axios.get(url, {
      responseType: 'blob'
    })

    // 获取响应体中的 Blob 数据
    const blob = new Blob([response.data], { type: response.headers['content-type'] })

    // 创建 File 对象
    const file = new File([blob], filename, { type: blob.type })

    return file
  } catch (error) {
    console.error('Failed to create file from URL:', error)
    throw error // 或者返回一个默认的 File 对象或 null
  }
}
export const uploadOssFromUrl = async (url: string) => {
  console.log('上传:', url)
  const fileName = url.split('/')?.slice(-1)?.[0]
  const fileNames = fileName?.split('.')
  const { data } = await service({
    method: 'post',
    url: '/hyb/oss/pri/upload_sign',
    data: {
      file_suffix: fileNames?.[1] || 'jpeg',
      file_name: fileNames?.[0],
      content_type: 'image/*',
      u: dayjs().valueOf(),
      vs: CryptoJS.MD5(`HYB:${dayjs().valueOf()}`).toString()
    }
  })
  console.log('上传data:', data)
  const formData = new FormData()
  // 分别添加每个键值对
  formData.append('object_key', data.object_key)
  formData.append('policy', data.policy)
  formData.append('OSSAccessKeyId', data.access_id)
  formData.append('signature', data.signature)
  formData.append('key', data.object_key)
  formData.append('Signature', data.signature)
  formData.append('success_action_status', '200')
  formData.append('x-oss-meta-file-name', fileName)
  formData.append('x-oss-content-type', 'image/*')

  // 添加其他数据
  Object.keys(data).forEach((key: any) => {
    formData.append(key, data[key])
  })
  // 添加文件
  const file = await createFileFromUrl(url, fileName)
  formData.append('file', file, fileName)
  await axios
    .post(data.host, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
        source: 'MINI'
      }
    })
    .then(() => console.log('upload success.'))
    .catch(() => {
      console.log('upload failed.')
      // message.error('upload failed.');
    })
    .finally(() => {
      console.log('upload finally.')
    })
  return data.object_key
}

/**
 * @description 亮度计算 0.179是一个经验阙值&计算公式基于ITU-R BT.709标准
 * @param color #000000
 * @returns boolean
 */
export const isDarkColor = (color: string) => {
  let r = 0
  let g = 0
  let b = 0
  if (color?.startsWith?.('rgba')) {
    const rgbs = color?.replace('rgba(', '').replace(')', '').split(',')
    r = parseInt(rgbs[0], 10)
    g = parseInt(rgbs[1], 10)
    b = parseInt(rgbs[2], 10)
  } else {
    const rgb = parseInt(color?.slice(1), 16) // Convert hex color to decimal
    r = (rgb >> 16) & 0xff
    g = (rgb >> 8) & 0xff
    b = rgb & 0xff
  }
  // console.log('color:', color, (0.2126 * r + 0.7152 * g + 0.0722 * b) / 255)
  return (0.2126 * r + 0.7152 * g + 0.0722 * b) / 255 < 0.5
}
export const calculateColor = (color: string) => {
  return !color || isDarkColor(color) ? '#fff' : '#000'
}
