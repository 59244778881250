import { Popup } from 'antd-mobile'
import { CloseOutline, SearchOutline } from 'antd-mobile-icons'
import React, { useRef } from 'react'

import { HSearchBar } from '@/components/NewEditForm/UILibrary/HYBVant'
import useSearch from '@/pages/schedule/advanced/hooks/useSearch'

import './index.less'

const SearchView: React.FC<any> = ({ keyword, setKeyword }) => {
  const searchRef = useRef<any>()
  const handleClick = () => {
    if (keyword) {
      setKeyword('')
    } else {
      onOpen?.()
      setTimeout(() => {
        searchRef.current?.focus?.()
      }, 200)
    }
  }
  const { show, value, setValue, onOpen, onClose, onConfirm } = useSearch({ setKeyword })
  return (
    <div className='list-search-view'>
      <div className='list-search' onClick={() => handleClick()}>
        {keyword ? (
          <CloseOutline className='icon' color='#ff4d4f' />
        ) : (
          <SearchOutline className='icon' />
        )}
      </div>
      <Popup
        visible={show}
        position='top'
        closeOnMaskClick
        onClose={() => onClose?.()}
        className='search-popup'
      >
        <div className='search-popup-content'>
          <HSearchBar
            ref={searchRef}
            defaultValue={value}
            onChange={(e: any) => setValue(e)}
            placeholder='搜索'
            showCancelButton
            onCancel={onClose}
            onSearch={() => onConfirm()}
          />
        </div>
      </Popup>
    </div>
  )
}

export default SearchView
