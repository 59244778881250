const useSetting = () => {
  const renderList = (props: any) => {
    const { fieldNames, options, dataList, searchText } = props
    let list: any[] = []
    const _list = options || dataList
    if (Array.isArray(_list) && _list.length > 0) {
      const { value, label } = fieldNames || {}
      list = _list.map((x: any) => ({
        ...x,
        key: x[value || 'value'],
        label: x[label || 'label'],
        value: x[value || 'value']
      }))
    }
    if (searchText) {
      list = list.filter((item: any) => item?.label?.includes(searchText))
    }
    return { list }
  }
  const change = (props: any) => {
    const { val, mode, setState, onChange } = props
    const state: any = {
      checkedValue: val
    }
    if (mode === 'single') {
      onChange?.(val?.[0])
      state.show = false
    }
    setState(state)
  }

  const renderSelectedValue = (props: any) => {
    const { value, list, mode } = props
    let selectedValue: string = ''
    if (mode === 'single') {
      selectedValue = list?.find((x: any) => x.value === value)?.label
    }
    if (mode === 'multiple') {
      const _list = list?.filter((x: any) => value?.includes(x?.value))
      selectedValue = _list?.map((y: any) => y?.label)?.join('、')
    }
    return { selectedValue }
  }

  return {
    renderList,
    change,
    renderSelectedValue
  }
}

export default useSetting
