import React from 'react'
import './index.less'

const Highlight = ({ children }: any) => {
  // style={{ whiteSpace: 'nowrap' }}
  return <span style={{ color: '#0256FF' }}>{children}</span>
}

interface KeywordHightlightProps {
  text: string
  keyword: string
}

function escapeRegExp(string: string) {
  return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&')
}

const KeywordHightlight: React.FC<KeywordHightlightProps> = ({ text, keyword }) => {
  if (!keyword) return <>{text}</>
  const regex = new RegExp(`(${escapeRegExp(keyword)})`)
  return text?.split(regex)?.map((part: any, index: number) =>
    regex.test(part) ? (
      <Highlight key={index}>{part}</Highlight>
    ) : (
      // style={{ whiteSpace: 'nowrap' }}
      <span key={index}>{part}</span>
    )
  )
}

export default KeywordHightlight
