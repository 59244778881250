import { useLocalObservable } from 'mobx-react-lite'
import { useEffect } from 'react'

import authStore from '@/store/global/authStore'
import dictStore from '@/store/global/dictStore'
import { getToken } from '@/utils'

export default function () {
  const quanxianStore = useLocalObservable(() => authStore)
  const zidianStore = useLocalObservable(() => dictStore)

  const init = () => {
    if (quanxianStore.status === 'pending') {
      if (getToken()) {
        quanxianStore.fetchMap()
        zidianStore.fetchDictMap()
      }
    }
  }

  useEffect(() => {
    init()
  }, [])
}
