import { useSetState } from 'ahooks'
import { Swiper, Divider, Image } from 'antd-mobile'
import { UpOutline, DownOutline, RightOutline, LeftOutline } from 'antd-mobile-icons'
import weekOfYear from 'dayjs/plugin/weekOfYear'
import { isEqual } from 'lodash-es'
import { observer } from 'mobx-react-lite'
import { forwardRef, useImperativeHandle } from 'react'
import { FC, useCallback, useMemo, useRef, useEffect, useContext, startTransition } from 'react'
import { useActivate } from 'react-activation'

import unifiedStore from '@/store/unified/unifiedStore'
import dayjs from '@/utils/dayjs'

import { TodayStr, WeekDayStr } from '../../const'

import Day from './Day'
import useStatus from './hooks/useStatus'
import { DayFormatStr, getCalendarMap, DefaultSwiperItems } from './utils'

import './index.less'

// 扩展dayjs
dayjs.extend(weekOfYear)

const WEEK_INDEX_MAP = ['一', '二', '三', '四', '五']

/**
 *  日历 - 筛选器
 */
interface ICalendarProps {
  currentDay: string
  onChange: Function
  getQueryParams?: Function
  // 分隔符
  header?: boolean
  // 分隔符
  divider?: boolean
  // 每日数据
  dataMap?: any
  // 是否显示当前周
  showCurrentWeek?: boolean
  // 是否显示日期面板
  showDayPanel?: boolean
  // 每日数据
  dataSource?: any[]
  // 最大日期
  maxDate?: string
  refresh?: Function
  // 周月模式
  expand?: boolean
  // 显示考勤状态
  showAttenaceStatus: boolean
  ref?: any
}
const FCalendar: FC<ICalendarProps> = forwardRef(
  (
    {
      currentDay,
      onChange,
      getQueryParams,
      dataMap,
      dataSource,
      refresh,
      divider = false,
      header = false,
      showCurrentWeek = false,
      showDayPanel = true,
      expand = true,
      maxDate = dayjs().add(-1, 'd').format('YYYY-MM-DD'),
      showAttenaceStatus = true
    },
    ref
  ) => {
    const stateStore = useContext(unifiedStore)
    const { dataMap: _dataMap, fetchDataMap } = useStatus()
    const [state, setState] = useSetState<{
      // 当前浏览日历的时间（当月/当周）
      currentView: string
      // 当前swiper的下标
      swiperIndex: number
      isNoData: boolean
      // 滑动方向
      direction?: 'prev' | 'next'
      // 月模式/周模式
      mode: 'month' | 'week'
    }>({
      currentView: currentDay || maxDate,
      swiperIndex: 50,
      isNoData: true,
      mode: 'week'
    })
    const { currentView, swiperIndex, mode, isNoData } = state
    const stateRef = useRef(state)
    stateRef.current = state
    const swiperRef = useRef<any>()
    // 选中某个日期
    const handleClick = (date: string) => {
      console.log('click:', date)
      setState({ currentView: date })
      onChange?.(date)
      refresh?.(date, stateRef.current.mode)
    }
    // 轮播变化
    const handleChange = useCallback(
      (index: number) => {
        const swiperNext = index > stateRef.current?.swiperIndex
        const isNext =
          typeof stateRef.current?.direction === 'string'
            ? stateRef.current?.direction === 'next'
            : swiperNext
        // console.log('direction:', stateRef.current?.direction, isNext)
        const unit = mode === 'month' ? 'M' : 'w'
        const _currentView = dayjs(stateRef.current?.currentView, DayFormatStr)
          .add(isNext ? 1 : -1, unit)
          .format(DayFormatStr)

        startTransition(() => {
          setState({ swiperIndex: index, currentView: _currentView, direction: undefined })
          setTimeout(() => {
            onChange?.(_currentView)
            refresh?.(_currentView, stateRef.current.mode)
          }, 400)
        })
      },
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [mode]
    )
    // 上一页
    const handlePrev = () => {
      const index =
        stateRef.current?.swiperIndex === 0
          ? DefaultSwiperItems.length - 1
          : stateRef.current?.swiperIndex - 1
      setState({ swiperIndex: index, direction: 'prev' })
      if (!showDayPanel) {
        return handleChange(index)
      }
      swiperRef.current?.swipeTo(index)
    }
    // 下一页
    const handleNext = () => {
      const index =
        stateRef.current?.swiperIndex === DefaultSwiperItems.length - 1
          ? 0
          : stateRef.current?.swiperIndex + 1
      setState({ swiperIndex: index, direction: 'next' })
      if (!showDayPanel) {
        return handleChange(index)
      }
      swiperRef.current?.swipeTo(index)
    }
    // 回到当天
    const handleBackToday = () => {
      setState({ currentView: TodayStr })
      onChange?.(TodayStr)
      refresh?.(TodayStr, stateRef.current.mode)
    }
    // 展开月模式/周模式
    const handleExpand = () => {
      const _mode = stateRef.current?.mode === 'month' ? 'week' : 'month'
      const _currentView = stateRef.current?.currentView || ''
      setState({ mode: _mode, currentView: _currentView })
      refresh?.(_currentView, _mode)
    }
    const {
      currentCalendar: { start, end, days }
    } = useMemo(() => {
      const _calendarMap = getCalendarMap({
        currentView: stateRef.current?.currentView,
        swiperIndex: stateRef.current?.swiperIndex,
        mode,
        dataSource
      })
      const {
        start: _start,
        end: _end,
        days: _days
      } = _calendarMap.get(`${stateRef.current?.swiperIndex}`) || {}
      // console.log('__start,end', _start, _end)
      return {
        calendarMap: _calendarMap,
        currentCalendar: {
          start: _start,
          end: _end,
          days: _days
        }
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentDay, currentView, swiperIndex, mode, dataSource])

    useEffect(() => {
      if (
        start &&
        end &&
        typeof fetchDataMap === 'function' &&
        showDayPanel &&
        showAttenaceStatus
      ) {
        fetchDataMap({
          ...getQueryParams?.(),
          start_at: start,
          end_at: end
        })
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [start, end, stateStore.filterState?.filterValues])

    // 从keepalive激活
    useActivate(async () => {
      if (start && end && typeof fetchDataMap === 'function' && showAttenaceStatus) {
        fetchDataMap({
          ...getQueryParams?.(),
          start_at: start,
          end_at: end
        })
      }
    })

    // 更新日历状态
    const refreshStatus = () => {
      if (start && end && typeof fetchDataMap === 'function' && showAttenaceStatus) {
        const _calendarMap = getCalendarMap({
          currentView: stateRef.current?.currentView,
          swiperIndex: stateRef.current?.swiperIndex,
          mode: stateRef.current?.mode,
          dataSource
        })
        const { start, end } = _calendarMap.get(`${stateRef.current?.swiperIndex}`) || {}
        fetchDataMap({
          ...getQueryParams?.(),
          start_at: start,
          end_at: end
        })
      }
    }

    useImperativeHandle(ref, () => {
      return {
        refreshStatus
      }
    }, [])

    // 列表页Filter组件互动支持
    useEffect(() => {
      if (stateStore.filterState.filterValues?.year_month) {
        setState({ currentView: stateStore.filterState.filterValues?.year_month })
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [stateStore.filterState.filterValues?.year_month])

    // 切换企业首页渲染
    useEffect(() => {
      refresh?.(currentView, mode)
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
      if (!isEqual(stateRef.current.currentView, currentDay)) {
        setState({ currentView: currentDay })
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentDay])

    const isDefault: boolean =
      mode === 'week' &&
      !days.some(
        (x: any) => Object.keys(x)?.includes('shift_info_list') && Array.isArray(x?.shift_info_list)
      )

    console.log('currentView:', currentDay, currentView, dataMap)
    const isEmptyObject = (obj: any) => {
      return !obj || (obj && Object.keys(obj)?.length === 0)
    }
    // 判断数据是否有状态
    useEffect(() => {
      const bool = isEmptyObject(dataMap || _dataMap) || displayDateStatus(dataMap || _dataMap)
      setState({ isNoData: bool })
    }, [dataMap || _dataMap, mode])
    const displayDateStatus = (obj: any): boolean => {
      if (!days?.length || !obj) return true

      if (mode === 'month') {
        for (const week of days) {
          if (!week) continue
          for (const data of week) {
            if (obj?.[data?.date]) return false
          }
        }
      } else {
        for (const data of days) {
          if (obj?.[data?.date]) return false
        }
      }
      return true
    }
    // 周一在哪个月就为哪个月的第几周，如9月1号为周二，当前周为八月第四/五周
    const currentWeek = Math.ceil(dayjs(dayjs(currentView).startOf('w')).date() / 7) - 1

    return (
      <div className='fcalendar'>
        {header && (
          <div className='fcalendar-hd'>
            <div className='fcalendar-hd-lt' onClick={() => handlePrev()}>
              <LeftOutline className='prev-icon' />
              {/* <Image src='https://s.huiyunban.cn/1723936687794425856.png' /> */}
            </div>
            <div className='fcalendar-hd-title'>
              <span className='fcalendar-hd-title-span'>
                {/* 周处理方式 */}
                {showCurrentWeek ? (
                  <>
                    {dayjs(currentView).startOf('w').format('YYYY - MM')}
                    <span className='current-week'>{`第${WEEK_INDEX_MAP[currentWeek]}周`}</span>
                  </>
                ) : (
                  dayjs(currentView, DayFormatStr).format('YYYY - MM')
                )}
              </span>
              {divider && (
                <div className='fcalendar-hd-link' onClick={() => handleBackToday()}>
                  回到当天
                </div>
              )}
            </div>
            <div className='fcalendar-hd-gt' onClick={() => handleNext()}>
              <RightOutline className='right-icon' />
              {/* <Image src='https://s.huiyunban.cn/1723936733143240704.png' /> */}
            </div>
          </div>
        )}
        {showDayPanel ? (
          <>
            <div className='fcalendar-bd'>
              <Swiper
                ref={swiperRef}
                indicator={() => null}
                defaultIndex={swiperIndex}
                onIndexChange={handleChange}
                style={
                  {
                    // height: toPx(mode === 'month' ? 96 * (days?.length || 1) : isDefault ? 162 : 190)
                  }
                }
              >
                {DefaultSwiperItems?.map((item, index) => (
                  <Swiper.Item key={`swiper${index}`}>
                    <div className='fcalendar-bd-swiper'>
                      <div className='fcalendar-bd-weekday'>
                        {WeekDayStr?.map((day: string) => (
                          <div key={day} className='fcalendar-bd-weekday-item'>
                            <span>{day}</span>
                          </div>
                        ))}
                      </div>
                      {mode === 'month' ? (
                        days?.map((week: any, _index: number) => (
                          <div className='fcalendar-bd-day' key={`${_index}`}>
                            {week?.map((data: any, weekIndex: number) => (
                              <Day
                                data={data}
                                start={start}
                                end={end}
                                maxDate={maxDate}
                                isNoData={isNoData}
                                active={currentView}
                                businessData={dataMap?.[data?.date] || _dataMap?.[data?.date]}
                                onClick={handleClick}
                                key={data?.date}
                                isDefault={isDefault}
                              />
                            ))}
                          </div>
                        ))
                      ) : (
                        <div className='fcalendar-bd-day'>
                          {days?.map((data: any, dayIndex: number) => (
                            <Day
                              data={data}
                              start={start}
                              end={end}
                              maxDate={maxDate}
                              isNoData={isNoData}
                              active={currentView}
                              businessData={dataMap?.[data?.date] || _dataMap?.[data?.date]}
                              onClick={handleClick}
                              key={data?.date}
                              isDefault={isDefault}
                            />
                          ))}
                        </div>
                      )}
                    </div>
                  </Swiper.Item>
                ))}
              </Swiper>
            </div>

            <div className='fcalendar-ft'>
              {expand ? (
                <Divider contentPosition='center'>
                  <div className='fcalendar-ft-expand' onClick={() => handleExpand()}>
                    {mode === 'month' ? (
                      <>
                        <UpOutline className='icon' />
                        <span className='expand-str'>收起</span>
                      </>
                    ) : (
                      <>
                        <DownOutline className='icon' />
                        <span className='expand-str'>展开</span>
                      </>
                    )}
                  </div>
                </Divider>
              ) : (
                <div style={{ height: 10 }} />
              )}
            </div>
          </>
        ) : null}
      </div>
    )
  }
)

export default observer(FCalendar)
