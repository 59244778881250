export default function getQuery(key: any) {
  const urls = window.location.href || ''
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const url = urls.replace(/&amp;/g, '&')
  if (url.includes('?')) {
    const vars = url.split('?')[1]
    const list = vars.split('&')
    for (const value of list) {
      const pair = value.split('=')
      if (pair[0] === key) {
        return pair[1]
      }
    }
  }
  return null
}
