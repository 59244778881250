import { useSetState } from 'ahooks'
import { Popup, SafeArea, CalendarPickerView, PickerView, Ellipsis } from 'antd-mobile'

import dayjs from 'dayjs'
import React, { useEffect, useMemo, useRef } from 'react'

import HCell from '../../HYBVant/HCell'

import { SixMonth } from '../CalendarCascader/constant'

import './index.less'
import useMultiple from './useMultiple'
import MultiplePicker from './MultiplePicker'
import { isNil } from 'lodash-es'

interface CalendarDatePickerState {
  visible: boolean
}

interface CalendarDatePickerProps {
  [index: string]: any
}

const CalendarDatePicker: React.FC<CalendarDatePickerProps> = (props) => {
  const {
    title = '开始日期',
    value,
    onConfirm,
    disabled = false,
    readed,
    mode,
    range = 6,
    shouldDisableDate
  } = props
  const { sixMonthsAgo, sixMonthsfFuture } = SixMonth(range)
  const [{ visible }, setState] = useSetState<CalendarDatePickerState>({
    visible: false
  })
  const calendarRef = useRef<any>(null)

  const confirm = (date: string) => {
    onConfirm?.(date)
    mode !== 'multiple' && setState({ visible: false })
  }
  useEffect(() => {
    setTimeout(() => {
      const _value: string = value || dayjs().format('YYYY-MM-DD')
      const _list = _value?.split?.('-')
      _list && calendarRef.current?.jumpTo({ year: Number(_list?.[0]), month: Number(_list?.[1]) })
      !value &&
        props?.min &&
        calendarRef.current?.jumpTo({
          year: dayjs(props?.min).get('year'),
          month: dayjs(props?.min).get('month')
        })
    }, 10)
  }, [calendarRef, visible, value, props.min])

  const valueStr = useMemo(() => {
    if (Array.isArray(value)) {
      return value
        ?.sort((prev: any, next: any) => (dayjs(prev).valueOf() > dayjs(next).valueOf() ? 1 : -1))
        ?.join(' ')
    }
    return value
  }, [value])

  const bool: boolean = !props?.disabled && !props?.readed
  console.log('props.value', props.value)

  return (
    <div className='calender-rili'>
      <HCell
        value={
          !value ? (
            <span style={{ color: '#BFBFC2', fontWeight: 'normal' }}>
              请选择{mode !== 'multiple' ? '' : '(可多选)'}
            </span>
          ) : (
            <Ellipsis content={valueStr} rows={3} />
          )
        }
        showPlaceholder={!value}
        onClick={() => bool && setState({ visible: true })}
        disabled={disabled}
        readed={readed}
      />
      <Popup
        visible={visible}
        onClose={() => setState({ visible: false })}
        onMaskClick={() => setState({ visible: false })}
        className='sheet-popup'
      >
        <div className='calender-rili-form'>
          <div className='calender-rili-form-action'>
            <div
              className='calender-rili-form-action-i'
              onClick={() => setState({ visible: false })}
            >
              取消
            </div>
            <div className='calender-rili-form-action-ii'>{title}</div>

            {mode === 'multiple' ? (
              <div
                className='calender-rili-form-action-iii'
                onClick={() => setState({ visible: false })}
              >
                确认
              </div>
            ) : (
              <div className='calender-rili-form-action-iii' />
            )}
          </div>
          <div className='calender-rili-form-edit'>
            {mode === 'multiple' ? (
              <MultiplePicker {...props} visible={visible} />
            ) : (
              <CalendarPickerView
                ref={calendarRef}
                defaultValue={value ? dayjs(value) : props?.min ? undefined : dayjs()}
                allowClear={false}
                min={props?.min || sixMonthsAgo}
                max={props?.max || sixMonthsfFuture}
                shouldDisableDate={shouldDisableDate}
                weekStartsOn='Monday'
                onChange={(date: any) => confirm(dayjs(date).format('YYYY-MM-DD'))}
              />
            )}
          </div>
          <SafeArea position='bottom' />
        </div>
      </Popup>
    </div>
  )
}

export default CalendarDatePicker
