// ====================== 基础
// import Taro from '@tarojs/taro'

import { observable, makeAutoObservable, action, runInAction, toJS } from 'mobx'
import { createContext } from 'react'

import logger from '@/utils/logger'

import alertDialog from '../components/DialogBox'
import { scheduleSettingMobile } from '../service'

/**
 *  全局状态
 */

class SechduleStore {
  constructor() {
    makeAutoObservable(this)
  }
  @observable virtualList: any[] = [] // 虚拟列表数据源
  @observable isPublish: boolean = true // 是否发布
  @observable unPublish: number = 0 // 未发布
  @observable orgId: any = '' // 部门id
  @observable orgInfo: any = {} // 部门id数据
  @observable pickerTypeDate: any = [] // 周期时间
  @observable dateType: any = 'date' // 配置周期类型
  @observable setting: any = {}
  @observable valueName: any = ''
  @observable isDeleteMode: any = false // 橡皮擦
  @observable isClear: any = false // 清空班表
  @observable isIntroduce: any = false // 引入班表
  @observable isClearClass: any = false // 删除班表
  @observable copyOrClearLoading: any = false // 清空班表/ 引入班表 loading
  @observable scheduleFailures: any[] = [] // 删除班表报错数据
  @observable extraOpen: boolean = false
  @observable extraContent: string = ''
  // set虚拟列表数据源
  @action setVirtualList(list: any[]) {
    this.virtualList = list
  }
  // set单个虚拟列表数据源
  @action setVirtual({ row, col, data }: any) {
    this.virtualList = this.virtualList?.map((item: any, index: number) => {
      return {
        ...item,
        children: item?.children?.map((_item: any, _index: number) => {
          if (index === row && _index === col) {
            console.log('picker::::::', row, col)
            return {
              ...data,
              shouldUpdate: true
            }
          }
          return _item
        })
      }
    })
    console.log('this.virtualList:::', toJS(this.virtualList))
  }
  // set单个虚拟列表的行数据
  @action setVirtualRow({ row, data }: any) {
    this.virtualList = this.virtualList?.map((item: any, index: number) => {
      if (index === row) {
        return {
          ...item,
          ...data
        }
      }
      return item
    })
    console.log('this.virtualList:::', toJS(this.virtualList))
  }
  @action
  setIsPublish = (bool: boolean, uncount = 0) => {
    this.isPublish = bool
    this.unPublish = uncount
  }
  // 部门id
  @action getOrgId(v: any) {
    this.orgId = v?.id || ''
    this.orgInfo = v
  }
  // 配置周期类型
  @action getDateType(v: any) {
    this.dateType = v
  }
  // 删除班表报错数据
  @action getFailuresData(data: any, title?: string) {
    alertDialog({ data, title })
  }
  // 配置周期类型
  @action getPickerTypeDate(v: any) {
    this.pickerTypeDate = v
  }
  // 搜索信息
  @action getValueName(v: any) {
    this.valueName = v
  }
  // 搜索信息
  @action getCopyOrClearLoading(bool: boolean) {
    this.copyOrClearLoading = bool
  }
  // 删除班表
  @action getClearClass(bool: boolean) {
    this.isClearClass = bool
  }
  // 清空班表/ 引入班表 loading
  @action getClearShow(bool: boolean) {
    this.isClear = bool
  }
  // 清空班表/ 引入班表 loading
  @action getIntroduceShow(v: boolean) {
    this.isIntroduce = v
  }
  // 配置信息
  @action
  async runScheduleSetting() {
    try {
      const { data } = await scheduleSettingMobile()
      // await 之后，再次修改状态需要动作:
      runInAction(() => {
        this.setting = data || {}
      })
    } catch (error) {
      logger(error)
    }
  }
  // 删除模式
  @action setDeleteMode(v: boolean) {
    this.isDeleteMode = v
  }
  @action setExtraOpen(v: boolean, content: string) {
    this.extraOpen = v
    this.extraContent = content
  }
  @action clearData = () => {
    this.resetData()
  }
  // 重置初始化
  @action
  resetData(isExit?: boolean) {
    this.virtualList = []
    this.orgId = ''
    this.dateType = 'date'
    isExit && (this.pickerTypeDate = [])
    this.isDeleteMode = false
    this.setting = {}
    this.orgInfo = {}
    this.valueName = ''
    this.isClear = false
    this.isIntroduce = false
    this.copyOrClearLoading = false
    this.isClearClass = false
    this.isShow = false
    this.scheduleFailures = []
  }
}
const scheduleStoreNoContext = new SechduleStore()
export const scheduleStoreWithContext = createContext(scheduleStoreNoContext)
export default scheduleStoreNoContext
