import { useSetState } from 'ahooks'

const useSearch = ({ setKeyword }: any) => {
  const [{ show, value }, setState] = useSetState({
    show: false,
    value: ''
  })
  console.log('value:', value)
  const setValue = (_value: string) => {
    setState({
      value: _value
    })
    if (!_value) {
      setKeyword('')
    }
  }
  const onOpen = () => {
    setState({
      show: true
    })
  }
  const onClose = () => {
    setState({
      show: false,
      value: ''
    })
  }
  const onConfirm = () => {
    setKeyword(value)
    setState({
      show: false
    })
  }
  return { show, value, setValue, onOpen, onClose, onConfirm }
}
export default useSearch
