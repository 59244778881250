import dayjs from 'dayjs'

import { CalendarCascaderEnum } from './type'

export const FormatDate = (date: string) => {
  const year: number = dayjs(date).year()
  const month: number = dayjs(date).month()
  const day: number = dayjs(date).date()
  return `${year}年${month + 1}月${day}日`
}

export const mapColumnsFn: (
  props: Record<string, any>
) => Array<{ label: string; value: string }>[] = (props) => {
  const { timeColumns, config } = props

  const mapColumns: Record<string, Array<{ label: string; value: string }>[]> = {
    [CalendarCascaderEnum.Half]: [
      [
        { label: '上午', value: 'AM' },
        { label: '下午', value: 'PM' }
      ]
    ],
    [CalendarCascaderEnum.Time]: timeColumns
  }
  return mapColumns[config]
}

export const SixMonth = (range: number = 6) => {
  const now = new Date()
  const sixMonthsAgo = new Date(now)
  sixMonthsAgo.setMonth(now.getMonth() - range)
  const sixMonthsfFuture = new Date(now)
  sixMonthsfFuture.setMonth(now.getMonth() + range)
  return {
    sixMonthsAgo,
    sixMonthsfFuture
  }
}
