import { useSetState } from 'ahooks'
import { Popup, SafeArea, Tabs, CalendarPickerView, PickerView } from 'antd-mobile'

import dayjs from 'dayjs'
import React, { useEffect, useRef } from 'react'

import useTimeColumn from '@/hooks/useTimeColumn'

import HCell from '../../HYBVant/HCell'

import { SixMonth, mapColumnsFn } from './constant'
import { CalendarCascaderEnum } from './type'
import useSettingColumn from './useSettingColumn'

import './index.less'

interface CalendarCascaderState {
  visible: boolean
  activeKey: string
  list: string[]
}

interface CalendarCascaderProps {
  columns?: Array<{ label: string; value: string }>[]
  value: [any, any]
  [index: string]: any
}

const CalendarCascader: React.FC<CalendarCascaderProps> = (props) => {
  const {
    title = '开始日期',
    okText = '确认',
    value,
    onConfirm,
    disabled = false,
    readed = false,
    config = CalendarCascaderEnum.Time,
    columns,
    timeProps = {
      format: 'HH:mm'
    },
    range = 6
  } = props
  const [{ visible, activeKey, list }, setState] = useSetState<CalendarCascaderState>({
    visible: false,
    activeKey: '0',
    list: []
  })
  const calendarRef = useRef<any>(null)
  const { renderTimeColumn } = useTimeColumn()
  const { sixMonthsAgo, sixMonthsfFuture } = SixMonth(range)
  const { basicColumns: timeColumns } = renderTimeColumn(timeProps)

  const { pickColumns, renderTitle, renderDefaultValue, renderDefaultList, columnChange } =
    useSettingColumn(setState)
  const { _columns, flatColumns } = pickColumns({
    columns,
    timeColumns,
    mapColumnsFn,
    config
  })
  const { calendarTitle, cascaderTitle, cellTitle } = renderTitle({
    columns: _columns,
    value,
    flatColumns,
    config,
    list
  })
  const { calendarDefaultValue, cascaderDefaultValue } = renderDefaultValue({
    value,
    columns: _columns,
    flatColumns
  })

  const { list: _list } = renderDefaultList({
    calendarDefaultValue: calendarDefaultValue?.format('YYYY-MM-DD'),
    cascaderDefaultValue,
    columns: _columns
  })

  const confirm = () => {
    onConfirm?.(list)
    setState({ visible: false })
  }

  useEffect(() => {
    if (Array.isArray(value) && value.length > 0) {
      setState({ list: value })
      return
    }
    setState({ list: _list })
  }, [])

  useEffect(() => {
    setTimeout(() => {
      if (list.length === 0) return
      const _list = list?.[0]?.split('-')
      calendarRef.current?.jumpTo({ year: Number(_list?.[0]), month: Number(_list?.[1]) })
    }, 10)
    if (!visible) setState({ activeKey: '0' })
  }, [list, calendarRef, visible])

  const bool: boolean = !disabled && !readed

  return (
    <div className='calender-cascader'>
      <HCell
        value={
          cellTitle.length === 0 ? (
            <span style={{ color: '#BFBFC2', fontWeight: 'normal' }}>请选择</span>
          ) : (
            cellTitle.join(' ')
          )
        }
        showPlaceholder={!value}
        onClick={() => bool && setState({ visible: true })}
        disabled={disabled}
        readed={readed}
      />
      <Popup
        visible={visible}
        onClose={() => setState({ visible: false })}
        onMaskClick={() => setState({ visible: false })}
        className='sheet-popup'
      >
        <div className='calender-cascader-form'>
          <div className='calender-cascader-form-action'>
            <div
              className='calender-cascader-form-action-i'
              onClick={() => setState({ visible: false })}
            >
              取消
            </div>
            <div className='calender-cascader-form-action-ii'>{title}</div>
            <div className='calender-cascader-form-action-iii' onClick={confirm}>
              {okText}
            </div>
          </div>
          <div className='calender-cascader-form-edit'>
            <Tabs
              defaultActiveKey='0'
              activeKey={activeKey}
              onChange={(activeKey) => setState({ activeKey })}
            >
              <Tabs.Tab title={calendarTitle} key='0'>
                <div>
                  <CalendarPickerView
                    ref={calendarRef}
                    defaultValue={calendarDefaultValue}
                    onChange={(date: any) => {
                      setState({
                        activeKey: '1',
                        list: [date ? dayjs(date).format('YYYY-MM-DD') : list?.[0], list?.[1]]
                      })
                    }}
                    allowClear={false}
                    min={sixMonthsAgo}
                    max={sixMonthsfFuture}
                  />
                </div>
              </Tabs.Tab>
              <Tabs.Tab title={cascaderTitle} key='1'>
                <PickerView
                  columns={_columns}
                  defaultValue={cascaderDefaultValue}
                  onChange={(val) => columnChange({ val, columns: _columns, list, setState })}
                />
              </Tabs.Tab>
            </Tabs>
          </div>
          <SafeArea position='bottom' />
        </div>
      </Popup>
    </div>
  )
}

export default CalendarCascader
