import { SearchSelectApi } from './SearchSelectPopup/constant'

export default function (type?: string, scheduleSetting?: any) {
  const fetchData = () => {
    const getNameCode = (item: any, fieldName: string) => {
      if (type === 'Regular') {
        return scheduleSetting?.tool_regular_temp_info?.includes?.('NAME') &&
          scheduleSetting?.tool_regular_temp_info?.includes?.('CODE')
          ? `${item.name}/${item.code}`
          : scheduleSetting?.tool_regular_temp_info?.includes?.('CODE')
            ? item.code
            : item.name
      }
      return item[fieldName || 'name']
    }
    const fomatdata = (list: any[] = [], fieldNames: any) => {
      const { value, name, code } = fieldNames || {}
      const data = list?.map((x) => ({
        ...x,
        id: x[value || 'id'],
        name: getNameCode(x, name),
        value: x[value || 'id'],
        label: x[name || 'name'],
        code: x[code || 'code']
      }))
      return {
        data
      }
    }

    const getFetchList = async (props: any) => {
      const { type, options, dataList } = props
      const list: any[] = options || dataList
      const localOrDictionary: boolean = list && Array.isArray(list)
      if (localOrDictionary) {
        return Promise.resolve({ data: list })
      }

      if (!localOrDictionary) {
        return await SearchSelectApi[type]?.()
      }
      return Promise.resolve({ data: list })
    }
    return {
      fomatdata,
      getFetchList
    }
  }

  const format = () => {
    const transformvalue = (popup: string, data: any) =>
      popup === 'array' ? data : data?.[0]?.value
    const transformtitle = (props: any) => {
      const { list, popup, checkedValue, fieldNames } = props
      const matched = list?.find?.((x: any) => x.value === checkedValue)
      const title =
        popup === 'array'
          ? list
              ?.filter((x: any) => checkedValue?.map((y: any) => y?.id).includes(x?.id))
              ?.map?.((item: any) =>
                fieldNames?.code === 'regular_type_name'
                  ? `${item?.[fieldNames?.name]}（${item?.[fieldNames?.code]}）`
                  : item?.name
              )
              ?.join?.('、')
          : matched?.name
      return title
    }
    const transformCheckedValue = (props: any) => {
      const { currentList, value = [] } = props

      let currentvalue: any[] = value
      if (Array.isArray(value) && value?.length > 0) {
        const bool = value?.every((x: any) => typeof x === 'string' || typeof x === 'number')
        if (bool) currentvalue = currentList?.filter((x: any) => value?.includes(String(x.id)))
      }
      return currentvalue
    }
    const transformdefaultvalue = (props: any) => {
      const { list, popup, checkedValue } = props
      const value =
        popup === 'array' ? checkedValue : list?.filter((x: any) => x.value === checkedValue)
      return value
    }
    return {
      transformvalue,
      transformtitle,
      transformdefaultvalue,
      transformCheckedValue
    }
  }

  return {
    fetchData,
    format
  }
}
