/* eslint-disable prettier/prettier */
import { useSetState } from 'ahooks'
import { Checkbox, Popup, Tabs } from 'antd-mobile'
import { cloneDeep } from 'lodash-es'
import { observer } from 'mobx-react'
import React, { useEffect } from 'react'

import { useEventManager } from '@/components/EventManagerProvider'
import HErrorBlock from '@/components/HErrorBlock'
import { calculateColor } from '@/utils'

import HCell from '../../HYBVant/HCell'
import usePopup from '../../HYBVant/HPopup/hooks/usePopup'

import SearchView from './SearchView'
import './index.less'

const List: React.FC<any> = ({
  value,
  onConfirm,
  fieldNames = { label: 'name', value: 'id' },
  disabled,
  renderLabel,
  label
}: any) => {
  const { open, modalData, setModalData, onOpen, onCancel, onOk } = usePopup({
    afterOk: async () => {
      onConfirm(modalData?.value)
      return Promise.resolve(1)
    }
  })
  // console.log(toJS(tabListMap[scheduleTableOperType]), toJS(mode), '888')
  const handleAddOrDel = (data: any, isChecked: boolean) => {
    const newValue = cloneDeep(modalData?.value || [])
    if (isChecked) {
      newValue.splice(
        newValue.findIndex((item: any) => item[fieldNames?.value] === data[fieldNames?.value]),
        1
      )
    } else {
      newValue.push(data[fieldNames?.value])
    }
    setModalData({ value: newValue })
  }
  const eventManager = useEventManager()
  const setDataSource = (_: any) => setModalData({ dataSource: _ })
  const initValue = value
  useEffect(() => {
    setModalData({ value: value || [] })
  }, [initValue])
  useEffect(() => {
    eventManager?.on('CardSelect.setDataSource', setDataSource)
    return () => {
      eventManager?.off('CardSelect.setDataSource', setDataSource)
    }
  }, [])
  console.log('modalDaata', modalData)
  return (
    <>
      {renderLabel ? (
        renderLabel({ value, onClick: onOpen, disabled })
      ) : (
        <HCell
          value={value
            ?.map?.(
              (item: any) =>
                modalData?.dataSource?.find((i: any) => i[fieldNames.value] === item)?.[
                  fieldNames.label
                ]
            )
            .join('；')}
          onClick={() => onOpen({ value: value || [] })}
          disabled={disabled}
        />
      )}
      <Popup className='card-select' visible={open} onClose={onCancel} position='bottom'>
        <Tabs defaultActiveKey='1' stretch={false}>
          <Tabs.Tab title={label || '选择班次'} key='1' />
        </Tabs>
        <div className='list'>
          <div className='list-left'>
            <SearchView
              keyword={modalData?.keyword}
              setKeyword={(e: any) => setModalData({ keyword: e })}
            />
          </div>
          <div className='list-content'>
            <div className='list-content-scroll'>
              <Checkbox.Group value={modalData?.value || []}>
                <div className='list-content-inner'>
                  {modalData?.dataSource?.filter((item: any) =>
                    modalData?.keyword ? item.keyword?.match(modalData?.keyword) : true
                  )?.length > 0 ? (
                    modalData?.dataSource
                      ?.filter((item: any) =>
                        modalData?.keyword ? item.keyword?.match(modalData?.keyword) : true
                      )
                      .map((item: any) => {
                        return (
                          <div
                            key={item?.[fieldNames?.value]}
                            className='list-content-item'
                            onClick={() =>
                              handleAddOrDel(
                                item,
                                modalData?.value?.includes(item?.[fieldNames?.value])
                              )
                            }
                          >
                            <div
                              className='list-content-card'
                              style={
                                item?.color
                                  ? {
                                      backgroundColor: item?.color,
                                      color: calculateColor(item?.color)
                                    }
                                  : {}
                              }
                            >
                              {item?.text}
                            </div>
                            <Checkbox value={item?.[fieldNames?.value]} />
                          </div>
                        )
                      })
                  ) : (
                    <HErrorBlock className='advanced-empty-wrap' title='暂无数据' />
                  )}
                </div>
              </Checkbox.Group>
            </div>
          </div>
        </div>
        <div className='btns'>
          <div className='btn default' onClick={() => onCancel()}>
            取消
          </div>
          <div className='btn info' onClick={() => onOk()}>
            确定
          </div>
        </div>
      </Popup>
    </>
  )
}

export default observer(List)
