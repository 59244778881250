import { useSetState } from 'ahooks'
import { Popup, SafeArea, CalendarPickerView } from 'antd-mobile'

import dayjs from 'dayjs'
import React, { useEffect, useRef } from 'react'

import HCell from '../../HYBVant/HCell'

import { SixMonth } from '../CalendarCascader/constant'

import './index.less'

interface CalendarRangeDatePickerState {
  visible: boolean
  list: string[]
}

interface CalendarRangeDatePickerProps {
  [index: string]: any
}

const CalendarRangeDatePicker: React.FC<CalendarRangeDatePickerProps> = (props) => {
  const { sixMonthsAgo, sixMonthsfFuture } = SixMonth()
  const { title = '日期范围', value, onConfirm, disabled = false, readed, placeholder } = props
  const [{ visible, list }, setState] = useSetState<CalendarRangeDatePickerState>({
    visible: false,
    list: []
  })
  const calendarRef = useRef<any>(null)

  const confirm = () => {
    onConfirm?.(list)
    setState({ visible: false })
  }

  useEffect(() => {
    setTimeout(() => {
      const _value: string = dayjs().format('YYYY-MM-DD')
      const _list = _value?.split('-')
      calendarRef.current?.jumpTo({ year: Number(_list?.[0]), month: Number(_list?.[1]) })
    }, 10)
    setState({ list: value || [] })
  }, [calendarRef, visible])

  const bool: boolean = !props?.disabled && !props?.readed

  console.log('CalendarRangeDatePicker>>>', value, props, list)

  const label: string =
    Array.isArray(value) && value?.length > 0 ? `${value?.[0]} - ${value?.[1]}` : ''

  return (
    <div className='calender-cascader'>
      <HCell
        value={
          label || (
            <span style={{ color: '#BFBFC2', fontWeight: 'normal' }}>
              {placeholder || '请选择'}
            </span>
          )
        }
        showPlaceholder={value?.length === 0}
        onClick={() => bool && setState({ visible: true })}
        disabled={disabled}
        readed={readed}
      />
      <Popup
        visible={visible}
        onClose={() => setState({ visible: false })}
        onMaskClick={() => setState({ visible: false })}
        className='sheet-popup'
      >
        <div className='calender-cascader-form'>
          <div className='calender-cascader-form-action'>
            <div
              className='calender-cascader-form-action-i'
              onClick={() => setState({ visible: false })}
            >
              取消
            </div>
            <div className='calender-cascader-form-action-ii'>{title}</div>
            <div className='calender-cascader-form-action-iii' onClick={confirm}>
              确认
            </div>
          </div>
          <div className='calender-cascader-form-edit'>
            <CalendarPickerView
              ref={calendarRef}
              defaultValue={[new Date(list?.[0]), new Date(list?.[1])]}
              selectionMode='range'
              allowClear={false}
              min={sixMonthsAgo}
              max={sixMonthsfFuture}
              onChange={(date: any) => {
                setState({
                  list: [
                    dayjs(date?.[0]).format('YYYY-MM-DD'),
                    dayjs(date?.[1]).format('YYYY-MM-DD')
                  ]
                })
              }}
            />
          </div>
          <SafeArea position='bottom' />
        </div>
      </Popup>
    </div>
  )
}

export default CalendarRangeDatePicker
