import { isEmpty } from 'lodash-es'
import { useEffect } from 'react'

import { toPx } from '@/utils'
import dayjs from '@/utils/dayjs'

import useMultiple from './useMultiple'

function getElementOffsetTopWithScroll(
  element: Element,
  container: Element,
  isOffsetBottom?: boolean
) {
  const elementRect = element.getBoundingClientRect()
  const containerRect = container.getBoundingClientRect()
  // 加上容器的 scrollTop 以考虑到已滚动的距离
  return (
    elementRect.top - containerRect.top + container.scrollTop + (isOffsetBottom ? toPx(103) : 0)
  )
}
function scrollTo(value: any[]) {
  const scrollView = document.querySelector('.sheet-popup .adm-calendar-picker-view-body')
  const firstPick = dayjs(value[0]).format('YYYY-M')
  const isOffsetBottom = dayjs(value[0]).date() > 20
  if (scrollView?.children) {
    const targets = Array.from(scrollView.children)
    const target = targets.find((item) => item.getAttribute('data-year-month') === firstPick)
    if (target) {
      const y = getElementOffsetTopWithScroll(target, scrollView, isOffsetBottom)
      console.log('scroll Y:', y)
      scrollView.scrollTo(0, y)
    }
  }
}
export default (props: any) => {
  const monthes = useMultiple(props)
  const today = dayjs().format('YYYY-MM-DD')
  useEffect(() => {
    if (props.visible && Array.isArray(props.value) && !isEmpty(props.value)) {
      setTimeout(() => scrollTo(props.value), 50)
    } else if (props.visible && isEmpty(props.value)) {
      setTimeout(() => scrollTo([props?.min || today]), 50)
    }
  }, [props.visible, props?.min])
  return (
    <div className='adm-calendar-picker-view'>
      <div className='adm-calendar-picker-view-header'>
        <div className='adm-calendar-picker-view-title'>日期选择</div>
      </div>
      <div className='adm-calendar-picker-view-mark'>
        <div className='adm-calendar-picker-view-mark-cell'>一</div>
        <div className='adm-calendar-picker-view-mark-cell'>二</div>
        <div className='adm-calendar-picker-view-mark-cell'>三</div>
        <div className='adm-calendar-picker-view-mark-cell'>四</div>
        <div className='adm-calendar-picker-view-mark-cell'>五</div>
        <div className='adm-calendar-picker-view-mark-cell'>六</div>
        <div className='adm-calendar-picker-view-mark-cell'>日</div>
      </div>
      <div className='adm-calendar-picker-view-body'>{monthes}</div>
    </div>
  )
}
