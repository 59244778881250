import { http } from '@/utils'

export const getUploadSign = (data: {
  file_suffix: string
  file_name: string
  content_type?: string
  u: number
  vs: string
}): any => http.post('/hyb/oss/pri/upload_sign', data)

export const _getUploadSign = (data: {
  file_suffix: string
  file_name: string
  content_type?: string
  u: number
  vs: string
}): any => http.post('/hyb/oss/pri/upload_sign', data)

export const batchDownloadSigns = (data: any): any =>
  http.post('/hyb/oss/pri/batch_download_signs', { data })

export const _batchDownloadSigns = (data: any): any =>
  http.post('/hyb/oss/pri/batch_download_signs', data)
