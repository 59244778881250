import React from 'react'
import './index.less'

const Warnning: React.FC = (props: any) => {
  const { code, disabled = true } = props
  const mapItem: Record<string, string[]> = {
    EXTERNAL_HELPER: [
      '1.The helper requisition must be submitted 3days in advance and approved by Department Head or Division Head unless is emergency case.除非紧急情况，应提前三天提出人员申请，并报请部门经理或部门总监批准。',
      '2.This form which has already been approved must attach with the payment for Helper Company. 在申请结算劳务费用时必须附有经过批准的此表。'
    ],
    INTERNAL_HELPER: [
      '1.The helper requisition must be submitted 3days in advance and approved by Department Head or Division Head unless is emergency case.除非紧急情况，应提前三天提出人员申请，并报请部门经理或部门总监批准。',
      '2.This form which has already been approved must attach with the payment for Helper Company. 在申请结算劳务费用时必须附有经过批准的此表。'
    ]
  }
  const mapTitle: Record<string, string> = {
    EXTERNAL_HELPER: 'Note注意事项：',
    INTERNAL_HELPER: 'Note注意事项：'
  }
  const list: any[] = mapItem[code] || []
  const title: string = mapTitle[code] || ''
  if (list.length === 0 || disabled) return null
  return (
    <div className='form-warnning'>
      <div className='form-warnning-title'>{title}</div>
      {list?.map((x: any) => <div className='form-warnning-item'>{x}</div>)}
    </div>
  )
}

export default Warnning
