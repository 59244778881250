import dayjs from 'dayjs'
import isoWeek from 'dayjs/plugin/isoWeek'
import weekday from 'dayjs/plugin/weekday'

import 'dayjs/locale/zh-cn'

// config dayjs
dayjs.extend(weekday)
dayjs.locale('zh-cn')
dayjs.extend(isoWeek)

export default dayjs
