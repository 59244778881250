import { Ellipsis } from 'antd-mobile'
import React, { memo, useMemo, useState } from 'react'

import './index.less'

const Expand: React.FC<any> = ({ dataSource }) => {
  return (
    dataSource.remark && (
      <div className={`time-line-clock-item-content-remark`}>
        <span className='time-line-clock-item-content-remark-label'>备注：</span>
        <div className='time-line-clock-item-content-remark-value'>
          <Ellipsis
            direction='end'
            content={dataSource.remark}
            expandText='展开'
            collapseText='收起'
          />
        </div>
      </div>
    )
  )
}

export default memo(Expand)
