import { Form, Picker, Tabs, Badge } from 'antd-mobile'
import { isNil } from 'lodash-es'
import { toJS } from 'mobx'
import { observer } from 'mobx-react-lite'
import { useContext, useEffect } from 'react'

import { useLocation, useNavigate } from 'react-router-dom'

import useFunctionCall from '@/components/NewEditForm/hooks/useFunctionCall'
import PickerBox from '@/components/NewEditForm/UILibrary/HYBElement/PickerBox'
import { HSearchBar } from '@/components/NewEditForm/UILibrary/HYBVant'
import HInput from '@/components/NewEditForm/UILibrary/HYBVant/HInput'
import HTabs from '@/components/NewEditForm/UILibrary/HYBVant/HTabs'
import { type IPageProps } from '@/components/UnifiedPage/interface'
import { unifiedOptionStore, unifiedStore } from '@/store'
import { isNotEmptyArray } from '@/utils'

import { DatePicker, OrgFilter, renderFormItem, RenderNode } from './components'
import './index.less'

/**
 * 通用列表布局组件
 * @returns FC
 */
const Filter = ({
  dataSource,
  optionStore
}: {
  optionStore: IPageProps
  dataSource: {
    runQuery?: Function
    form_list?: any[]
    confirm_form_list?: any[]
    reset_field_name_list?: string[]
  }
}) => {
  const stateStore = useContext(unifiedStore)
  const navigate = useNavigate()
  const location = useLocation()
  const { form_list = [], confirm_form_list = [], reset_field_name_list = [] } = dataSource
  const [form] = Form.useForm()
  console.log('location.state:', location.state)
  const { hideExec, onAfterChangeExec } = useFunctionCall({ form })
  const resetFieldValues = isNotEmptyArray(reset_field_name_list)
    ? reset_field_name_list.reduce((res: any, key: string) => {
        res[key] = null
        return res
      }, {})
    : {}
  const handleChange = (changed: any) => {
    stateStore.setFilterState({
      filterValues: { ...stateStore.filterState?.filterValues, ...resetFieldValues, ...changed }
    })
    stateStore.setTableState({
      selectedKeys: [],
      selectedItems: []
    })
  }
  /**
   * 定制平铺筛选器
   * @param item
   * @returns
   */
  const renderFilterItem = (item: any, index: number) => {
    switch (item.type) {
      case 'Input':
        return (
          <Form.Item name='' label='' key={`Input-${index}`}>
            <HSearchBar
              onChange={(e: any) => {
                const text = item?.formItemProps?.name || item?.name
                const info: any = {
                  [text]: e
                }
                handleChange(info)
              }}
              placeholder={item?.formItemProps?.placeholder || item?.placeholder}
            />
          </Form.Item>
        )
      case 'Select':
        return (
          <Form.Item name='' label='' key={`Select-${index}`}>
            <PickerBox
              onConfirm={(e: any) => {
                const text = item?.formItemProps?.name || item?.name
                const info: any = {
                  [text]: e
                }
                handleChange(info)
              }}
              {...item.elementProps}
            />
          </Form.Item>
        )
      case 'OrgFilter':
        return (
          <Form.Item name='' label='' key={`OrgFilter-${index}`}>
            <OrgFilter
              {...item.elementProps}
              onConfirm={(e: any) => {
                const text = item?.formItemProps?.name || item?.name
                const info: any = {
                  [text]: e
                }
                handleChange(info)
              }}
            />
          </Form.Item>
        )
      case 'DatePicker':
        return (
          <Form.Item name='' label='' key={`DatePicker-${index}`}>
            <DatePicker
              value={
                stateStore.filterState?.filterValues?.[item?.formItemProps?.name || item?.name]
              }
              onConfirm={(e: any) => {
                const text = item?.formItemProps?.name || item?.name
                const info: any = {
                  [text]: e
                }
                handleChange(info)
              }}
              {...item.elementProps}
            />
          </Form.Item>
        )
      case 'Tabs':
        return (
          <HTabs
            key={`Tabs-${index}`}
            defaultActiveKey={location.state?.[item?.formItemProps?.name || item.name]}
            onChange={(val: any) => {
              navigate(location.pathname, {
                state: {
                  [item?.formItemProps?.name || item.name]:
                    item.elementProps?.dataList?.[val]?.value
                },
                replace: true
              })
              const changed = {
                [item?.formItemProps?.name || item.name]: item.elementProps?.dataList?.[val]?.value
              }
              if (Array.isArray(item?.elementProps.clearFields)) {
                item?.elementProps.clearFields?.reduce((prev: any, next: any) => {
                  prev[next] = undefined
                  return prev
                }, changed)
              }
              handleChange(changed)
            }}
          >
            {(item.elementProps?.dataList || []).map((tab: any, dataIndex: number) => {
              const showTotal = item.elementProps?.showTotal || tab?.showTotal
              const list = stateStore.filterState.tabsTotal || []
              const title = (
                <Badge
                  // content={list?.[dataIndex] || ''}
                  style={{ '--right': '-10px', '--top': '8px' }}
                >
                  {tab.text}
                </Badge>
              )
              return <Tabs.Tab key={dataIndex} title={showTotal ? title : tab.text} />
            })}
          </HTabs>
        )
    }
    return null
  }

  useEffect(() => {
    const defaultFiles = (
      isNotEmptyArray(form_list) ? form_list.filter((item) => item.required) : []
    ).reduce((res, item) => {
      const { dataList } = item?.elementProps || {}
      const firstValue = isNotEmptyArray(dataList) ? dataList[0].value : undefined
      if (!isNil(firstValue)) {
        res[item.name] = firstValue
      }
      return res
    }, {})
    const tabsFieldName = form_list.find((item) => item.type === 'Tabs')?.name
    const _filterState: any = {
      form,
      filterValues: {
        ...defaultFiles,
        ...optionStore.pageOption?.services?.query?.data
      }
    }
    if (tabsFieldName && location.state?.[tabsFieldName]) {
      _filterState.filterValues[tabsFieldName] = location.state?.[tabsFieldName]
    }
    stateStore.setFilterState(_filterState)
  }, [form_list])

  console.log(confirm_form_list, 'confirm_form_list')

  return (
    <div className='unified-filter-wrapper'>
      <Form form={form}>
        <div className='unified-filter'>
          {form_list?.map(renderFilterItem)}
          {confirm_form_list?.length > 0 && (
            <Form.Item {...renderFormItem('Expand')}>
              <RenderNode
                type='Expand'
                elementProps={{
                  form,
                  form_list: confirm_form_list,
                  globalProps: { state: {}, option: {}, hideExec, onAfterChangeExec }
                }}
              />
            </Form.Item>
          )}
        </div>
      </Form>
    </div>
  )
}

export default observer(Filter)
