import { useSetState } from 'ahooks'
import { Popup, SafeArea, PickerView } from 'antd-mobile'
import React, { useEffect } from 'react'
import classnames from 'classnames'

import useTimeColumn from '@/hooks/useTimeColumn'

import HCell from '../../HYBVant/HCell'
import PickerBox from '../PickerBox'

import './index.less'

interface InputTimeGroupState {
  visible: boolean
  timeValue: string[]
}

const InputTimeGroup: React.FC<any> = (props) => {
  const {
    value = {},
    disabled = false,
    readed = false,
    format = 'HH:mm',
    okText = '确认',
    onConfirm,
    options = [],
    defaultValue = {
      cloumns1: '00:00',
      cloumns2: 'CURRENT_DAY'
    }
  } = props

  const { renderTimeColumn } = useTimeColumn()
  const { basicColumns: timeColumns } = renderTimeColumn({ format })

  const bool: boolean = !disabled && !readed
  const [{ visible, timeValue }, setState] = useSetState<InputTimeGroupState>({
    visible: false,
    timeValue: []
  })

  const confirm = (data: any) => {
    onConfirm?.(data)
    setState({ visible: false })
  }

  const titleCloumns2: string = options?.find((x: any) => x.value === value?.cloumns2)?.name

  const title: any = (
    <div className='input-time-group-title'>
      <div
        className={classnames('input-time-group-title-i', {
          'input-time-group-title-i-placeholder': !value?.cloumns1,
          'input-time-group-title-i-value': !!value?.cloumns1
        })}
        onClick={() => bool && setState({ visible: true })}
      >
        {value?.cloumns1 || '请输入'}
      </div>{' '}
      <PickerBox
        readed={readed}
        dataList={options}
        value={value?.cloumns2}
        handleChange={(val: any) => {
          confirm({ ...value, cloumns2: val })
        }}
        renderItem={(onClick: any) => <div onClick={bool && onClick}>{titleCloumns2}</div>}
      />
    </div>
  )

  useEffect(() => {
    Object.keys(value).length === 0 && confirm(defaultValue)
  }, [value])

  return (
    <div className='input-time-group'>
      <HCell
        value={title}
        onClick={() => null}
        showPlaceholder={!value}
        disabled={disabled}
        readed={readed}
      />
      <Popup
        visible={visible}
        onClose={() => setState({ visible: false })}
        onMaskClick={() => setState({ visible: false })}
        className='sheet-popup'
      >
        <div className='calender-cascader-form'>
          <div className='calender-cascader-form-action'>
            <div
              className='calender-cascader-form-action-i'
              onClick={() => setState({ visible: false })}
            >
              取消
            </div>
            <div className='calender-cascader-form-action-ii'>{''}</div>
            <div
              className='calender-cascader-form-action-iii'
              onClick={() => confirm({ ...value, cloumns1: `${timeValue?.[0]}:${timeValue?.[1]}` })}
            >
              {okText}
            </div>
          </div>
          <div className='calender-cascader-form-edit'>
            <PickerView
              columns={timeColumns}
              value={timeValue}
              onChange={(val: any) => setState({ timeValue: val })}
            />
          </div>
          <SafeArea position='bottom' />
        </div>
      </Popup>
    </div>
  )
}

export default InputTimeGroup
