import http from '@/utils/http'
import service from '@/utils/service'
// 排班列表
export const getMobileSchedule = (data: any) =>
  http.post(`/schedule/plan/schedule/schedule_list`, data)
// 发布排班
export const updateSchedulePublish = (data: any) => http.post(`/schedule/auto/publish`, data)
// 获取发布状态 员工和所有
export const getPublishStatus = (data: any) =>
  http.post(`/schedule/plan/schedule/unpublish_count`, data)
// 移动端排班配置查询
export const scheduleSettingMobile = (params?: any) =>
  http.get(`/schedule/setting/mobile`, {
    data: params
  })
// 清空多人多天班次
export const clearScheduleShift = (data: any) =>
  http.post(`/schedule/plan/clear_schedule_shift`, data)
// 恢复多人多天班次
export const recoverScheduleShift = (data: any) =>
  http.post(`/schedule/plan/restore_actual_schedule`, data)
// 一人一排班编辑
export const editClasses = (data: any) => http.post('/schedule/plan/shift_schedule', data)
// 用于排岗(一人一天班次编辑)
export const editPost = (data: any) => http.post('/schedule/plan/shift_schedulePlan', data)
// 多人多天排岗位
export const addPost = (data: any): any => http.post('/schedule/plan/batch_schedule_post', data)
// 编辑任务
export const editTask = (data: any) => http.post('/schedule/plan/edit_schedule_task', data)
// 删除任务（一个班次）
export const del1Task = (data: any) => http.post('/schedule/plan/delete_schedule_task', data)
// 删除排班(一个班次)
export const delSchedule = (data: any) => http.post('/schedule/plan/delete_schedule_shift', data)
// 多人多天排任务
export const addTask = (data: any): any => http.post('/schedule/plan/batch_schedule_task', data)
// 清空任务（多个班次）
export const delTask = (data: any) => http.post('/schedule/plan/clear_schedule_task', data)
// 清空多人多天班次
export const delClasses = (data: any) => http.post('/schedule/plan/clear_schedule_shift', data)
// 获取岗位
export const getPost = (data: any) => http.get('/schedule/plan/schedule/post_rank_list', data)

// 一人多天排班
export const saveSchedule = (data: any) => http.post('/schedule/plan/batch_emp_schedule', data)

// 一人一天排班
export const saveShiftSchedule = (data: any): any =>
  http.post('/schedule/plan/shift_schedulePlan', data)
// 复制排班 多人多天
export const copyPlan = (data: any) => http.post('/schedule/plan/copy', data)
// 一人多天排班
export const batchEmpSchedule = (data: any) => http.post(`/schedule/plan/batch_emp_schedule`, data)

// 批量修改排班日期类型
export const editDates = (data: any) => http.post('/schedule/plan/dates', data)
// 清除排班日期
export const delDates = (data: any) => http.delete(`/schedule/plan/dates`, { data })
// 新增/删除收藏夹
export const addFavi = (data: any) =>
  service({
    method: 'post',
    url: '/schedule/shift/favorite',
    data
  })
