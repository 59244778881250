/* eslint-disable import/order */
import { useSetState } from 'ahooks'
import { Checkbox, Radio } from 'antd-mobile'
import { RightOutline } from 'antd-mobile-icons'
import classNames from 'classnames'
import { throttle, cloneDeep } from 'lodash-es'
import { observer } from 'mobx-react'
import React, { useEffect, Fragment } from 'react'

import { HErrorBlock } from '@/components'
import KeywordHightlight from '@/components/KeywordHightlight'
import { HSearchBar } from '@/components/NewEditForm/UILibrary/HYBVant'

import { employeeSelectorApi, organizeSearchMap } from './services'

import './index.less'
// eslint-disable-next-line import/order
import { isNotEmptyArray } from '@/utils'

interface OrganizeComponentProps {
  onOk?: (employee: any[]) => void
  onClose?: () => void
  type?: string
  mode?: string
  [key: string]: any
}

const OrganizeComponent: React.FC<OrganizeComponentProps> = (props) => {
  const { onOk, type = '', mode = 'single', ...rest } = props

  const { organizeSearchRequestParams = { auth: 'Auth' }, selectorParams } = rest[type] || {}

  const [
    {
      searchStr,
      cascader,
      defaultCount,
      selectAll,
      selectOrg,
      currentOrg,
      originalCurrentOrg,
      originalOrg,
      selectEmployeeOrg
    },
    setState
  ] = useSetState<any>({
    cascader: [],
    defaultCount: 0,
    selectAll: false,
    selectOrg: [],
    currentOrg: [],
    employee: [],
    searchStr: '',
    originalCurrentOrg: [],
    selectEmployeeOrg: []
  })

  const onChange = (e: any) => {
    const start = throttle(async () => {
      const searPrams: Record<string, any> = {
        keyword: e,
        sub_flag: true,
        enable_status: 1,
        query_deleted: false
      }
      if (e) {
        // eslint-disable-next-line no-unsafe-optional-chaining
        const { data } =
          // eslint-disable-next-line no-unsafe-optional-chaining
          await organizeSearchMap[organizeSearchRequestParams?.auth || 'Auth']?.(searPrams)
        setState({
          currentOrg: {
            childs: data
          },
          searchStr: e,
          count: data?.length
        })
        return
      }
      const _currentOrg = cloneDeep(
        originalCurrentOrg?.length === 0 ? originalOrg : originalCurrentOrg
      )
      // _currentOrg.childs = _currentOrg?.childs.filter((x) => x?.name.includes(e.detail))
      console.log(_currentOrg, '_currentOrg', originalOrg)
      setState({
        searchStr: '',
        currentOrg: _currentOrg
      })
    }, 800)
    start()
  }

  const selectChildOrg = (_currentOrg: any) => {
    const exist: boolean = cascader.some((x) => x.id === _currentOrg.id)
    const bool: boolean = _currentOrg.id === originalOrg?.id
    let _cascader = []
    if (exist) {
      const index = cascader?.indexOf(_currentOrg)
      _cascader = cascader?.slice(0, index + 1)
    }
    setState({
      currentOrg: _currentOrg,
      originalCurrentOrg: cloneDeep(_currentOrg),
      cascader: bool ? [] : exist ? _cascader : [...cascader, _currentOrg]
    })
  }

  const fetchEmployeeSelector = async () => {
    const { data } = await employeeSelectorApi(selectorParams || {})
    console.log(data, 'data---123fetchEmployeeSelector')
    const orgList = (data?.item_infos || []).find((x) => x?.code === 'org').item_values.filter(Boolean) || []
    console.log(orgList, 'orgList')
    setState({
      defaultCount: orgList?.[0]?.metadata?.all_count,
      currentOrg: cloneDeep(
        {
          childs: [orgList?.[0]].filter(Boolean)
        } || {}
      ),
      originalOrg: cloneDeep(
        {
          childs: [orgList?.[0]].filter(Boolean)
        } || {}
      )
    })
  }

  const confrimSlected = () => {
    if (selectEmployeeOrg?.length === 0) return
    console.log(selectEmployeeOrg, 'selectEmployeeOrg')
    onOk?.(selectEmployeeOrg)
  }

  useEffect(() => {
    fetchEmployeeSelector()
  }, [])

  useEffect(() => {
    const getOrgSelectAll = (): boolean => {
      if (!currentOrg?.childs || currentOrg?.childs?.length === 0) return false
      if (currentOrg?.childs && currentOrg?.childs?.length > 0) {
        return selectEmployeeOrg.length === currentOrg?.childs.length
      }
      return false
    }
    setState({
      selectAll: getOrgSelectAll()
    })
  }, [currentOrg, selectEmployeeOrg])

  const handleSelectOrg = async (params: { orgList: string[]; org: Record<string, any> }) => {
    const { orgList = [], org } = params
    if (
      orgList.some((x) => selectEmployeeOrg.map((y) => y.id).includes(x)) &&
      mode === 'multiple'
    ) {
      const _selectEmployeeOrg: any[] = []
      for (const t of selectEmployeeOrg) {
        if (!orgList.includes(t?.id)) _selectEmployeeOrg.push(t)
      }
      setState({ selectEmployeeOrg: _selectEmployeeOrg })
    } else {
      setState({
        selectEmployeeOrg: mode === 'multiple' ? [org, ...selectEmployeeOrg] : [org]
      })
    }
  }

  const renderOrganize = () => {
    const ComponentList: Record<string, any> = {
      multiple: Checkbox.Group,
      single: Radio.Group
    }
    const ComponentChildList: Record<string, any> = {
      multiple: Checkbox,
      single: Radio
    }

    const ComponentValue: Record<string, any> = {
      multiple: selectOrg,
      single: selectOrg[0]
    }
    const Component = ComponentList[mode]
    const ComponentChild = ComponentChildList[mode]

    const ComponentProps = {
      value: ComponentValue[mode],
      onChange: (e: any) => {
        setState({ selectOrg: mode === 'multiple' ? e : [e] })
      }
    }

    return (
      <Component {...ComponentProps}>
        {Array.isArray(currentOrg?.childs) && currentOrg?.childs?.length > 0 ? (
          currentOrg?.childs.map((x: any) => {
            const hasNext = !searchStr && isNotEmptyArray(x?.childs)
            return (
              <div
                key={x?.id}
                className={classNames('personnel-select-org-item', {
                  'personnel-select-org-item-search': !!searchStr
                })}
              >
                <ComponentChild
                  value={x?.id}
                  disabled={
                    x?.metadata?.is_click === 0 && organizeSearchRequestParams?.auth !== 'NoAuth'
                  }
                  onClick={async () => {
                    x?.metadata?.is_click !== 0 && handleSelectOrg({ orgList: [x?.id], org: x })
                  }}
                >
                  <div>
                    <KeywordHightlight text={x?.name} keyword={searchStr} />
                  </div>
                  {searchStr && (
                    <div className='personnel-select-org-item-path'>
                      <KeywordHightlight
                        text={cloneDeep(x?.org_names)?.join('/')}
                        keyword={searchStr}
                      />
                    </div>
                  )}
                </ComponentChild>
                <div
                  className={classNames('personnel-select-org-item-child', {
                    'personnel-select-org-item-child-disabled': false,
                    hidden: !hasNext
                  })}
                  onClick={() => hasNext && selectChildOrg(x)}
                >
                  下级
                </div>
              </div>
            )
          })
        ) : (
          <HErrorBlock size='large' />
        )}
      </Component>
    )
  }
  const renderCount = () => {
    if (mode === 'single') return '1'
    if (defaultCount) return defaultCount
    // if (searchStr) return count
    return 0
  }

  console.log(currentOrg, 'currentOrg')

  return (
    <div className='personnel-select'>
      <div className='personnel-select-search'>
        <HSearchBar defaultValue={searchStr} onChange={onChange} />
      </div>
      {cascader?.length > 0 && !searchStr && (
        <div className='personnel-select-display'>
          <span
            className='personnel-select-display-back'
            onClick={() => selectChildOrg(originalOrg)}
          >
            回到顶级
          </span>
          {cascader.map((x: any, index: number) => (
            <Fragment key={x.name}>
              <span
                className={index > 0 ? 'personnel-select-display-name' : ''}
                onClick={() => selectChildOrg(x)}
              >
                {x.name}
              </span>
              {index !== cascader.length - 1 && (
                <RightOutline className='personnel-select-display-icon' />
              )}
            </Fragment>
          ))}
        </div>
      )}
      {mode === 'multiple' && (
        <div className='personnel-select-all'>
          <Checkbox
            value={selectAll}
            onChange={async (e) => {
              const _selectAll = e
              const orgList: string[] = currentOrg?.childs?.map((x) => x?.id) || []
              setState({
                selectAll: _selectAll,
                disabled: _selectAll ? orgList : [],
                selectOrg: _selectAll ? orgList : [],
                selectEmployeeOrg: _selectAll ? [...currentOrg?.childs] : []
              })
            }}
          >
            全选
          </Checkbox>
        </div>
      )}
      <div className='personnel-select-employee'>{renderOrganize()}</div>
      <div className='personnel-select-selected'>
        <div className='personnel-select-selected-n'>
          已选择 （
          <span className='personnel-select-selected-n-i'>{selectEmployeeOrg?.length || 0}</span>
          {'/'}
          {renderCount()}）
        </div>
        <div className='personnel-select-selected-confrim' onClick={confrimSlected}>
          确定
        </div>
      </div>
    </div>
  )
}

export default observer(OrganizeComponent)
