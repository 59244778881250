import classNames from 'classnames'
import { Dayjs } from 'dayjs'
import { cloneDeep } from 'lodash-es'

import { useMemo } from 'react'

import dayjs from '@/utils/dayjs'

import { SixMonth } from '../CalendarCascader/constant'

export default ({
  min,
  max,
  value,
  onConfirm,
  shouldDisableDate
}: {
  min?: Date
  max?: Date
  value: any
  onConfirm: (v: any) => void
  shouldDisableDate?: (date: Date) => boolean
}) => {
  const today = dayjs().format('YYYY-MM-DD')
  const handleClick = (date: Dayjs) => {
    if (!shouldDisableDate?.(date.toDate())) {
      const values = Array.isArray(value) ? cloneDeep(value) : []
      const dateStr = date.format('YYYY-MM-DD')
      values.includes(dateStr) ? values.splice(values.indexOf(dateStr), 1) : values.push(dateStr)
      onConfirm(values)
    }
  }
  const renderDate = (date: Dayjs, isCurrent?: boolean, isDisabled?: boolean) => {
    const isActive = Array.isArray(value) ? value.includes(date.format('YYYY-MM-DD')) : false
    const isToday = date.format('YYYY-MM-DD') === today
    return isCurrent ? (
      <div
        className={classNames('adm-calendar-picker-view-cell', {
          'adm-calendar-picker-view-cell-selected adm-calendar-picker-view-cell-selected-begin adm-calendar-picker-view-cell-selected-end':
            isActive,
          'adm-calendar-picker-view-cell-disabled': isDisabled,
          'adm-calendar-picker-view-cell-today': isToday
        })}
        onClick={() => !isDisabled && handleClick(date)}
      >
        <div className='adm-calendar-picker-view-cell-top'>{isToday && '今日'}</div>
        <div className='adm-calendar-picker-view-cell-date'>{date.format('DD')}</div>
        <div className='adm-calendar-picker-view-cell-bottom'></div>
      </div>
    ) : (
      <div className='adm-calendar-picker-view-cell' />
    )
  }
  const renderMonth = (date: any) => {
    const dayjsDate = dayjs(date)
    const monthStr = dayjsDate.format('YYYY-M')
    const monthCnStr = dayjsDate.format('YYYY年M月')
    const isCurrent = (d: Dayjs) => dayjsDate.get('M') === d.get('M')
    const start = dayjsDate.startOf('M').startOf('week')
    const end = dayjsDate.endOf('M')
    const diffs = end.diff(start, 'days') + 1
    const dates = new Array(diffs).fill(0).map((_, i) => {
      return dayjs(start).add(i, 'days')
    })
    return (
      <div data-year-month={monthStr}>
        <div className='adm-calendar-picker-view-title'>{monthCnStr}</div>
        <div className='adm-calendar-picker-view-cells'>
          {dates?.map((item: any) =>
            renderDate(
              item,
              isCurrent(item),
              shouldDisableDate ? shouldDisableDate(item.toDate()) : false
            )
          )}
        </div>
      </div>
    )
  }
  const monthNodes = useMemo(() => {
    const { sixMonthsAgo, sixMonthsfFuture } = SixMonth()
    const minDate = min ? dayjs(min) : dayjs(sixMonthsAgo)
    const maxDate = max ? dayjs(max) : dayjs(sixMonthsfFuture)
    const defaultMonthes = minDate.isSame(maxDate, 'month') ? 1 : 2
    const diffs = maxDate.diff(minDate, 'month') || defaultMonthes
    const monthes = new Array(diffs).fill(0).map((_, i) => {
      return minDate.add(i, 'month')
    })
    return monthes?.map(renderMonth)
  }, [value, min])
  return monthNodes
}
