import { useDebounceFn, useSetState } from 'ahooks'
import { Input } from 'antd-mobile'
import classNames from 'classnames'
import React, { useEffect } from 'react'

import HCell from '../HCell'

import { publicEvents, publicProps } from '../utils'

const HInputDebounce: React.FC<any> = (elementProps: any) => {
  const [{ value }, setState] = useSetState<{ value?: string }>({ value: elementProps.value })
  const valueRef = React.useRef<string | undefined>(value)
  valueRef.current = value
  const toInput = () => {
    publicEvents.onChange.call(elementProps, valueRef.current, 'Input')
    elementProps?.onConfirm && elementProps?.onConfirm(valueRef.current)
  }
  const { run: runInput } = useDebounceFn(toInput, { wait: 50, trailing: true })
  const onInput = (val: any) => {
    const precision = elementProps?.precision
    const _val = ['number', 'tel'].includes(elementProps?.type)
      ? (precision ? val?.replace(/[^0-9.]/g, '') : val?.replace(/[^0-9]/g, '')) || val
      : val
    setState({ value: _val })
    runInput()
  }
  useEffect(() => {
    if (elementProps.value !== value) {
      setState({ value: elementProps.value })
    }
  }, [elementProps.value])

  useEffect(() => {
    return () => {
      setState({ value: undefined })
    }
  }, [])
  const type_info =
    elementProps?.type === 'number'
      ? { type: 'number', max: elementProps.max, min: elementProps.min }
      : {}

  const renderPlaceholder = () => {
    if (elementProps?.disabled) return ''
    return elementProps?.placeholder || '请输入'
  }

  const _value = elementProps?.value === 0 ? String(elementProps?.value) : elementProps?.value

  return (
    <div
      className={classNames('hyb-input', {
        'hyb-input-disabled': elementProps?.disabled,
        'hyb-input-placeholder': !_value
      })}
    >
      {elementProps?.readed ? (
        <HCell value={_value} readed={elementProps.readed} />
      ) : (
        <Input
          cursorSpacing={12}
          {...publicProps(elementProps, 'input')}
          {...type_info}
          placeholder={renderPlaceholder()}
          maxLength={elementProps.maxLength || elementProps.maxlength}
          value={value}
          onChange={onInput}
        />
      )}
    </div>
  )
}

export default HInputDebounce
