import { Image } from 'antd-mobile'

import { Template } from '../Contact'

export const ModelPageTemplate = (props: any) => {
  const { list = [], code = '', popup, tstr = {} } = props
  if (code === 'GONG_ZUO_JING_LI') {
    return (
      <div className='hyb-com-concat-content-wrapper'>
        {(list || []).map((x: any, index: number) => (
          <div key={x.name} className='hyb-com-concat-content'>
            <div className='hyb-com-concat-content-col-1'>
              <div className='hyb-com-concat-content-1'>{x?.SUO_ZAI_GONG_SI}</div>
              <div className='hyb-com-concat-content-2'>{x?.ZHI_WEI}</div>
              <div className='hyb-com-concat-content-3'>
                {x?.KAI_SHI_NIAN_YUE || '-'}&nbsp;&nbsp;至&nbsp;&nbsp;
                {x?.JIE_SHU_NIAN_YUE || '-'}
              </div>
            </div>
            <div className='hyb-com-concat-content-col-2'>
              <Image
                onClick={() => popup?.(index)}
                className='hyb-com-concat-content-col-2-img'
                src='https://s.huiyunban.cn/1747191709529280512.png'
              />
            </div>
          </div>
        ))}
      </div>
    )
  }
  if (code === 'JIA_TING_CHENG_YUAN') {
    const _props = {
      ...props,
      tstr: {
        i: 'JIA_TING_CHENG_YUAN_XING_MING',
        ii: 'GUAN_XI',
        iii: 'LIAN_XI_FANG_SHI'
      }
    }

    return Template(_props)
  }
}
