import {
  ACTIVE_COMPANY_KEY,
  COMPANY_LIST_KEY,
  LOGIN_INFO_KEY,
  TOKEN_KEY,
  USER_DATA_KEY,
  CORRELATED_SAAS_KEY,
  PHONE_KEY
} from '@/constants'

/**
 * 存储到localStorage或sessionStorage的项
 */
type StorageItem<T> = {
  value: T
  timestamp: number // 可选的时间戳，用于追踪项的创建或最后修改时间
}

/**
 * 本地存储的封装
 * @param type 存储类型，可以是 'local' 或 'session'，默认为 'local'
 * @param prefix 前缀
 */
export const createStorage = (type: 'local' | 'session' = 'local', prefix: string = 'hyb_') => {
  const storage = type === 'local' ? window.localStorage : window.sessionStorage

  /**
   * 从localStorage或sessionStorage获取项
   * @param key 项的键名
   * @returns 项的值
   */
  const get = (key: string): any | undefined => {
    const itemStr = storage.getItem(prefixKey(key))
    if (!itemStr) {
      return undefined
    }
    if (/^(\{|\[)/.test(itemStr)) {
      try {
        const item = JSON.parse(itemStr)
        // 兼容旧版存取逻辑
        return item?.timestamp ? item.value : item
      } catch (error) {
        console.error(`Failed to parse JSON for key ${key}:`, error)
        return undefined
      }
    }

    return itemStr
  }

  /**
   * 设置localStorage或sessionStorage项
   * @param key 项的键名
   * @param value 项的值
   */
  const set = (key: string, value: any): void => {
    let val
    if (typeof value === 'string') {
      val = value
    } else {
      val = value ? JSON.stringify(value) : ''
    }
    storage.setItem(prefixKey(key), val)
  }

  /**
   * 删除localStorage或sessionStorage项
   * @param key 项的键名
   */
  const remove = (key: string): void => {
    storage.removeItem(prefixKey(key))
  }

  /**
   * 清空所有项
   */
  const clear = (): void => {
    storage.clear()
  }

  /**
   * 添加前缀到键名
   * @param key 键名
   * @returns 带前缀的键名
   */
  const prefixKey = (key: string): string => {
    return `${prefix}${key}`
  }

  return { get, set, remove, clear }
}

// hyb_前缀
export const hybStorage = createStorage('local', 'hyb_')

export const storage = createStorage('local', '')

export const getToken = () => {
  return storage.get(TOKEN_KEY)
}

export const setToken = (str: string) => {
  str && storage.set(TOKEN_KEY, str)
}

export const removeToken = () => {
  storage.remove(TOKEN_KEY)
}

export const getUserData = () => {
  return storage.get(USER_DATA_KEY)
}

export const setUserData = (data) => {
  data && storage.set(USER_DATA_KEY, data)
}

export const getCompanyList = () => {
  return storage.get(COMPANY_LIST_KEY)
}

export const setCompanyList = (data) => {
  data && storage.set(COMPANY_LIST_KEY, data)
}

export const getActiveCompany = () => {
  return storage.get(ACTIVE_COMPANY_KEY)
}

export const setActiveCompany = (data) => {
  data && storage.set(ACTIVE_COMPANY_KEY, data)
}

export const getLoginInfo = () => {
  return storage.get(ACTIVE_COMPANY_KEY)
}

export const setLoginInfo = (data) => {
  data && storage.set(LOGIN_INFO_KEY, data)
}

export const getCorrelatedSaas = () => {
  return storage.get(CORRELATED_SAAS_KEY)
}

export const setCorrelatedSaas = (data) => {
  data && storage.set(CORRELATED_SAAS_KEY, data)
}

export const getPhone = () => {
  return storage.get(PHONE_KEY)
}

export const setPhone = (str: string) => {
  str && storage.set(PHONE_KEY, str)
}
