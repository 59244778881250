import { lazy } from 'react'

const Demo: Array<any> = [
  {
    path: '/demo',
    meta: {
      title: '招聘'
    },
    children: [
      {
        path: '/demo',
        element: lazy(() => import(/* webpackChunkName: "needsTabs" */ '@/pages/demo/index')),
        meta: {
          requiresAuth: true,
          title: 'demo',
          key: 'demo'
        }
      }
    ]
  }
]

export default Demo
