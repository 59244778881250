import { useSetState } from 'ahooks'
import { omit } from 'lodash-es'
import { observer } from 'mobx-react-lite'
import React, { type ReactNode, useEffect, useState } from 'react'

import { useEventManager } from '@/components/EventManagerProvider'
import HCell from '@/components/NewEditForm/UILibrary/HYBVant/HCell'
import HPopup from '@/components/NewEditForm/UILibrary/HYBVant/HPopup'
import useInited from '@/hooks/useInited'
import { isNotEmptyArray } from '@/utils'

import OrganizePopup from './OrganizePopup'
import { employeeSelectorApi, organizeSearchMap } from './services'

import './index.less'

interface ElementProps {
  type?: string
  mode?: string
  disabled?: boolean
  arrowIcon?: boolean | ReactNode
  config?: Record<string, any>
  requestParams?: Record<string, any>
}

interface OrganizeProps {
  elementProps?: ElementProps
  [index: string]: any
}

const Organize: React.FC<OrganizeProps> = (props: any) => {
  const { value, onConfirm, elementProps, seteleType = '', include_child = 0 } = props
  const { config, arrowIcon } = elementProps || {}
  const { defaultSelect } = config || {}
  const eventManager = useEventManager()
  const [, initSuccess] = useInited(1, 0, () => {
    organizeSearchMap.NoAuth({}).then(({ success, data }: any) => {
      if (success) {
        const node = data.find((item: any) => item.id === value?.[0]?.id)
        eventManager?.emit('NewEditForm.setState', {
          Organize: omit(node, ['childs', 'children'])
        })
      }
    })
  })
  const [{ checkedValue }, setState] = useSetState({
    checkedValue: []
  })
  const [visible, setVisible] = useState(false)

  const fetchEmployeeSelector = async () => {
    const { data } = await employeeSelectorApi()
    return (data?.item_infos || []).find((x: any) => x?.code === 'org').item_values || []
  }
  useEffect(() => {
    if (defaultSelect) {
      fetchEmployeeSelector().then((res) => {
        const defaultValue = [res?.[0]?.childs?.[0]]
        setState({
          checkedValue: defaultValue as any
        })
        onConfirm?.(defaultValue)
      })
    }
  }, [defaultSelect])

  useEffect(() => {
    setState({ checkedValue: value })
    if (value?.[0]?.id) {
      initSuccess()
    }
  }, [value])

  const onOk = (data: any) => {
    const params = data?.map((x: any) => omit({ ...x, include_child }, ['childs', 'children']))
    setState({
      checkedValue: params
    })
    onConfirm?.(params)
    onClose()
    eventManager?.emit('NewEditForm.setState', {
      Organize: omit(params?.[0], ['childs', 'children'])
    })
  }
  const onClose = () => {
    setVisible(false)
  }
  const title = (() => {
    if (!isNotEmptyArray(checkedValue)) {
      return ''
    }
    return `${checkedValue
      ?.slice(0, 3)
      ?.map?.((item: any) => item.name)
      ?.join?.('、')}${checkedValue.length > 3 ? `等${checkedValue?.length}组织` : ''}`
  })()
  const bool: boolean = !props?.disabled && !props?.readed

  console.log(props, 'organizeProps', checkedValue, include_child)
  return (
    <>
      {!seteleType ? (
        <HCell
          value={title}
          onClick={() => bool && setVisible(true)}
          disabled={props?.disabled}
          placeholder={props?.placeholder}
          arrowIcon={arrowIcon}
          readed={props?.readed}
        />
      ) : seteleType === 'switch_departments' ? (
        <div className='switch-departments' onClick={() => !props?.disabled && setVisible(true)}>
          <div className='switch-departments-name'>{title || '暂无部门'}</div>
          <div className='switch-departments-btn'>切换部门</div>
        </div>
      ) : null}
      <HPopup
        className='organize-popup-wrap'
        title='切换组织'
        closeOnMaskClick
        visible={visible}
        onMaskClick={onClose}
        onCancel={onClose}
        hasOk={false}
      >
        <OrganizePopup
          {...props}
          type={props?.type || ''}
          mode={props?.mode ?? 'single'}
          onClose={onClose}
          onOk={onOk}
        />
      </HPopup>
    </>
  )
}

export default observer(Organize)
