export const renderFormItem = (type?: string) => {
  const commonFormProps = {
    valueKey: 'value',
    valuePropName: 'value',
    trigger: 'onConfirm'
  }
  const formItemProps: any = {
    Cell: { ...commonFormProps, mutiLevel: true },
    DatePicker: {
      // ...x,
      ...commonFormProps,
      valueKey: 'value',
      trigger: 'onConfirm',
      valueFormat: (e: any) => e
    },
    Input: {
      ...commonFormProps,
      valueKey: 'value',
      trigger: 'onInput',
      validateTrigger: 'onBlur',
      valueFormat: (e) => e.detail.value
    },
    Select: {
      valueKey: 'value',
      trigger: 'onConfirm',
      valueFormat: (e) => e
    },
    Textarea: {
      ...commonFormProps,
      valueKey: 'value',
      trigger: 'onChange',
      validateTrigger: 'onBlur',
      valueFormat: (e) => e
    },
    Concat: {
      ...commonFormProps,
      valueFormat: (e) => e
    },
    Education: {
      ...commonFormProps,
      valueFormat: (e) => e
    },
    HealthCertificate: {
      ...commonFormProps,
      valueFormat: (e) => e
    },
    FormImage: {
      valueFormat: (e) => e,
      mutiLevel: true,
      ...commonFormProps
    },
    FormFileImage: {
      valueFormat: (e) => e,
      mutiLevel: true,
      ...commonFormProps
    },
    RadioGroup: {
      trigger: 'onChange'
    },
    Reshuffle: {
      ...commonFormProps,
      valueFormat: (e) => e
    },
    ApplyPersonnelSelect: {
      ...commonFormProps,
      mutiLevel: true,
      valueFormat: (e) => e
    },
    PersonnelSelect: {
      ...commonFormProps,
      mutiLevel: true,
      valueFormat: (e) => e
    },
    Cascader: {
      ...commonFormProps,
      mutiLevel: true,
      valueFormat: (e) => e
    },
    Organize: {
      ...commonFormProps,
      valueKey: 'value',
      trigger: 'onConfirm',
      mutiLevel: true,
      valueFormat: (e: any) => e
    },
    OrganizeSelect: {
      valueKey: 'value',
      trigger: 'onConfirm',
      mutiLevel: true,
      valueFormat: (e) => e
    },
    GroupSelect: {
      valueKey: 'value',
      trigger: 'onConfirm',
      mutiLevel: true,
      valueFormat: (e) => {
        return e
      }
    },
    Switch: {
      valueKey: 'checked'
    },
    RidioSelect: {
      valueFormat: (e) => e,
      ...commonFormProps
    },
    TodayPicker: {
      ...commonFormProps,
      mutiLevel: true,
      valueFormat: (e) => e
    },
    LocationSelect: {
      ...commonFormProps,
      mutiLevel: true,
      valueFormat: (e) => e
    },
    InputWithImage: {
      valueKey: 'value',
      trigger: 'onInput',
      validateTrigger: 'onBlur',
      valueFormat: (e) => e.detail.value
    },
    CityCascader: {
      ...commonFormProps,
      valueFormat: (e) => e
    },
    ButtonGroup: {
      ...commonFormProps,
      valueFormat: (e) => e
    },
    InputNumberRange: {
      valueKey: 'value',
      trigger: 'onInput',
      validateTrigger: 'onBlur',
      valueFormat: (e) => e,
      mutiLevel: true
    },
    Calendar: {
      valueKey: 'value',
      trigger: 'onConfirm',
      validateTrigger: 'onBlur',
      valueFormat: (e) => e,
      mutiLevel: true
    },
    InputSelect: {
      valueKey: 'value',
      trigger: 'onConfirm',
      validateTrigger: 'onBlur',
      valueFormat: (e) => e,
      mutiLevel: true
    },
    CheckboxSelect: {
      valueKey: 'value',
      trigger: 'onConfirm',
      validateTrigger: 'onBlur',
      valueFormat: (e) => e,
      mutiLevel: true
    },
    SearchSelect: {
      valueKey: 'value',
      trigger: 'onConfirm',
      validateTrigger: 'onBlur',
      valueFormat: (e) => e,
      mutiLevel: true
    },
    DatePickerAll: {
      valueKey: 'value',
      trigger: 'onConfirm',
      validateTrigger: 'onBlur',
      valueFormat: (e) => e,
      mutiLevel: true
    },
    CalendarCascader: {
      ...commonFormProps
    },
    CalendarRangeDatePicker: {
      ...commonFormProps
    },
    CalendarDatePicker: {
      ...commonFormProps
    },
    CardSelect: commonFormProps,
    Warnning: { ...commonFormProps },
    TimeRangePicker: { ...commonFormProps },
    InputTimeGroup: { ...commonFormProps },
    ModelPage: { ...commonFormProps }
  }
  return formItemProps[type || ''] || {}
}
