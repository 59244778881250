import { useSetState } from 'ahooks'
import { Popup, SafeArea, Tabs, PickerView } from 'antd-mobile'

import React, { useEffect, useMemo } from 'react'

import useTimeColumn from '@/hooks/useTimeColumn'

import HCell from '../../HYBVant/HCell'

import './index.less'

interface CalendarCascaderState {
  visible: boolean
  activeKey: string
  list: string[]
}

interface CalendarCascaderProps {
  columns?: Array<{ label: string; value: string }>[]
  value: [any, any]
  [index: string]: any
}

const TimeRangePicker: React.FC<CalendarCascaderProps> = (props) => {
  const {
    value,
    disabled = false,
    readed = false,
    format = 'HH:mm',
    okText = '确认',
    onConfirm
  } = props
  const [{ visible, activeKey, list }, setState] = useSetState<CalendarCascaderState>({
    visible: false,
    activeKey: '0',
    list: ['00:00', '00:00']
  })
  const { renderTimeColumn } = useTimeColumn()
  const { basicColumns: timeColumns } = renderTimeColumn({ format })

  const confirm = () => {
    onConfirm?.(list)
    setState({ visible: false })
  }

  useEffect(() => {
    onConfirm?.(value || list)
    setState({ list: value || list })
  }, [])

  const bool: boolean = !disabled && !readed

  const title: string = `${list?.[0] || ''}-${list?.[1] || ''}`

  const endTimeColumns = useMemo(() => {
    const startIndex = list?.[0].split(':')

    const bool: boolean = startIndex?.[1] === '59'

    const _columns = [
      timeColumns?.[0]?.filter(
        (x: any) =>
          Number(x.value) >= (bool ? Number(startIndex?.[0]) + 1 : Number(startIndex?.[0]))
      ),
      timeColumns?.[1]?.filter((x: any) => Number(x.value) >= (bool ? 0 : Number(startIndex?.[1])))
    ]

    console.log(startIndex, 'startIndex', timeColumns, Number('04'), _columns)
    return _columns
  }, [list, timeColumns])

  console.log(list, 'TimeRangePicker>>>>>', timeColumns, value, list?.[0]?.split(':'))

  return (
    <div className='calender-cascader'>
      <HCell
        value={title}
        showPlaceholder={!value}
        onClick={() => bool && setState({ visible: true })}
        disabled={disabled}
        readed={readed}
      />
      <Popup
        visible={visible}
        onClose={() => setState({ visible: false })}
        onMaskClick={() => setState({ visible: false })}
        className='sheet-popup'
      >
        <div className='calender-cascader-form'>
          <div className='calender-cascader-form-action'>
            <div
              className='calender-cascader-form-action-i'
              onClick={() => setState({ visible: false })}
            >
              取消
            </div>
            <div className='calender-cascader-form-action-ii'>{''}</div>
            <div className='calender-cascader-form-action-iii' onClick={confirm}>
              {okText}
            </div>
          </div>
          <div className='calender-cascader-form-edit'>
            <Tabs
              defaultActiveKey='0'
              activeKey={activeKey}
              onChange={(activeKey) => setState({ activeKey })}
            >
              <Tabs.Tab title={list?.[0]} key='0'>
                <PickerView
                  columns={timeColumns}
                  value={list?.[0]?.split(':')}
                  onChange={(val: any) => {
                    const _val = `${val?.[0]}:${val?.[1]}`
                    setState({ list: [_val, list?.[1]] })
                  }}
                />
              </Tabs.Tab>
              <Tabs.Tab title={list?.[1]} key='1'>
                <PickerView
                  columns={endTimeColumns}
                  value={list?.[1]?.split(':')}
                  onChange={(val: any) => {
                    const _val = `${val?.[0]}:${val?.[1]}`
                    setState({ list: [list?.[0], _val] })
                  }}
                />
              </Tabs.Tab>
            </Tabs>
          </div>
          <SafeArea position='bottom' />
        </div>
      </Popup>
    </div>
  )
}

export default TimeRangePicker
