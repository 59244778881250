import { useSetState } from 'ahooks'
import { observer, useLocalObservable } from 'mobx-react-lite'
import { useEffect } from 'react'

// import flowStore from '@/pages/Flow/Store'
import scheduleStoreNoContext from '@/pages/schedule/advanced/store'

import HCell from '../../HYBVant/HCell'
import usePopup from '../../HYBVant/HPopup/hooks/usePopup'

import SearchSelectPopup from './SearchSelectPopup'
import useSeting from './useSeting'

interface ElementProps {
  type: string
  mode?: string
  disabled?: boolean
  config?: Record<string, any> // {type: SelectSearchEnum }
  requestParams?: Record<string, any>
  popup: string // string: 单个， array: 数组（默认）
  value: Array<{ id: string; name: string }>[] | string
}

interface SearchSelectProps extends ElementProps {
  children?: any
  [index: string]: any
}

function SearchSelect(props: SearchSelectProps) {
  const {
    value,
    onConfirm,
    onAfterChange,
    popup = 'array',
    options,
    dataList,
    config,
    elementProps
    // elementProps: { onChange }
  } = props
  const { type: configType } = config || {}
  const fieldNames = config?.fieldNames || props?.fieldNames
  const store = useLocalObservable(() => scheduleStoreNoContext)
  const { format: useFormat, fetchData: useFetchData } = useSeting(configType, store.setting)
  const { getFetchList, fomatdata } = useFetchData()
  const { transformvalue, transformtitle, transformdefaultvalue, transformCheckedValue } =
    useFormat()
  const [{ checkedValue, currentList }, setState] = useSetState<any>({
    checkedValue: [],
    currentList: []
  })

  const { open, onOpen, onOk, onCancel } = usePopup({
    afterOk: (data?: any) => {
      const params = transformvalue(popup, data)
      onConfirm?.(params)
      const _onChange = elementProps?.onChange || props?.onChange
      _onChange?.(params)
      setState({
        checkedValue: value
      })
      onAfterChange?.(params)
      return Promise.resolve()
    }
  })

  useEffect(() => {
    const currentvalue = transformCheckedValue({ currentList, value })
    setState({ checkedValue: currentvalue })
  }, [value, currentList])

  const fetchSelectList = async () => {
    getFetchList({ type: configType, options, dataList, fieldNames }).then((res: any) => {
      const { data } = res
      const { data: list } = fomatdata(data, fieldNames)
      setState({ currentList: list, displaySelectList: list })
    })
  }

  const title = transformtitle({ checkedValue, list: currentList, popup, fieldNames })

  const defaultvalue = transformdefaultvalue({ checkedValue, list: currentList, popup })

  console.log(
    currentList,
    defaultvalue,
    'SearchSelectPopup:',
    props,
    fieldNames,
    checkedValue,
    title,
    value
  )
  const bool: boolean = !props?.disabled && !props?.readed

  useEffect(() => {
    fetchSelectList()
  }, [options, dataList, fieldNames, configType])

  return (
    <>
      <HCell
        value={title}
        onClick={() => bool && onOpen()}
        disabled={props.disabled}
        readed={props.disabled}
      />
      {open && (
        <SearchSelectPopup
          {...props}
          visible={open}
          onOk={onOk}
          onCancel={onCancel}
          currentList={currentList}
          defaultvalue={defaultvalue}
        />
      )}
    </>
  )
}

SearchSelect.transform = (value: any, config: any) => {
  const propName = config?.config?.fieldNames?.value
  return value?.[0]?.[propName || 'code'] || value
}

export default observer(SearchSelect)
